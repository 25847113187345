import { Box } from '@hope-ui/solid';
import { NavLink, useLocation } from 'solid-app-router';
import type { LinkProps } from 'solid-app-router';
import { splitProps } from 'solid-js';
import minimatch from 'minimatch';
import { MenuButton } from '.';
import type { MenuButtonProps } from '.';

export type MenuLinkProps = {
  /**
   * An array of glob strings for path matching.
   */
  selectionMatcher?: string | string[];
} & MenuButtonProps &
  LinkProps;

export const MenuLink = (props: MenuLinkProps) => {
  const location = useLocation();
  const [here, linkProps, rest] = splitProps(
    props,
    ['children'],
    ['href', 'replace', 'noScroll', 'state']
  );

  const isSelected = () => {
    if (props.selectionMatcher) {
      if (Array.isArray(props.selectionMatcher)) {
        for (const matcher of props.selectionMatcher) {
          if (minimatch(location.pathname, matcher)) return true;
        }
      } else if (minimatch(location.pathname, props.selectionMatcher))
        return true;
    }

    return location.pathname === props.href;
  };

  return (
    <Box as={NavLink} href={linkProps.href} textDecoration='none'>
      <MenuButton {...rest} selected={isSelected()}>
        {here.children}
      </MenuButton>
    </Box>
  );
};
