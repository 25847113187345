import {
  Component,
  createRenderEffect,
  createSignal,
  JSX,
  Show,
} from 'solid-js';
import { Icon, Text } from '@hope-ui/solid';
import { Motion, Presence } from '@motionone/solid';
import { Rerun } from '@solid-primitives/keyed';

import { AiOutlineStar } from 'solid-icons/ai';
import { FiClock, FiType } from 'solid-icons/fi';
import { ImCoinDollar } from 'solid-icons/im';

import { Box, Flex, Input } from '../global';
import FilterList from './FilterList';

const Pages = (props: { stack: JSX.Element[]; onRemovePage?: () => void }) => {
  const popStack = () => {
    if (props.stack.length > 1) props.onRemovePage();
  };

  return (
    <Box overflow='hidden' maxW='240px'>
      <Presence>
        <Show when={props.stack.length > 1}>
          <Motion
            initial={{ height: 0, y: -5 }}
            animate={{ height: '1.5rem', y: 0 }}
            exit={{ height: 0, y: -5 }}
            transition={{
              delay: 0.2,
              duration: 0.3,
            }}
            style={{ overflow: 'hidden' }}
          >
            <Text
              px='$2_5'
              pb='$1'
              fontSize='$xs'
              cursor='pointer'
              onclick={popStack}
              userSelect='none'
            >
              {'<'} Go Back
            </Text>
          </Motion>
        </Show>
      </Presence>
      <Presence exitBeforeEnter initial={false}>
        <Rerun on={props.stack.length}>
          <Motion
            id={props.stack.length}
            initial={{ x: -260 }}
            animate={{ x: 0 }}
            exit={{ x: 260 }}
            transition={{
              duration: 0.2,
            }}
          >
            {props.stack[props.stack.length - 1]}
          </Motion>
        </Rerun>
      </Presence>
    </Box>
  );
};

export type AddFilterProps = {
  onFilterSelected?: (filter: any) => void;
};

export const AddFilter: Component<AddFilterProps> = (props) => {
  const [pages, setPages] = createSignal<JSX.Element[]>([]);

  const addPage = (page: JSX.Element) => {
    setPages([...pages(), page]);
  };

  const removePage = () => {
    setPages(pages().slice(0, -1));
  };

  const [search, setSearch] = createSignal('');

  const handleFilterSelected = (filter: any) => {
    addPage(() => (
      <Box px='$2'>
        <Text>
          <Icon as={filter.icon} color='$accentBlue' /> {filter.name}
        </Text>
        <Text fontSize='$sm'>TODO: Add configurables...</Text>
      </Box>
    ));
  };

  const filterList = [
    { name: 'Item' },
    { name: 'Rarity', icon: AiOutlineStar },
    { name: 'Type', icon: FiType },
    { name: 'Time Range', icon: FiClock },
    { name: 'Currency', icon: ImCoinDollar },
  ];

  const Filters = () => {
    return (
      <FilterList
        search={search()}
        filterList={filterList}
        onFilterSelected={handleFilterSelected}
      />
    );
  };

  createRenderEffect(() => {
    if (pages().length === 0) addPage(Filters);
  });

  return (
    <Flex
      pos='absolute'
      flexDirection='column'
      bg='$overlayContainer'
      outline='1px solid $containerOutline !important'
      borderRadius='5px'
      w='240px'
      pb='$2'
      border='none'
      css={{
        backdropFilter: 'blur(4px)',
      }}
      {...props}
    >
      <Box p='$2'>
        <Input
          id='filterSearch'
          placeholder='Search'
          px='$2'
          py='$1'
          minH='auto'
          value={search()}
          onInput={(e: any) => setSearch(e.target.value)}
        />
      </Box>
      <Pages stack={pages()} onRemovePage={removePage} />
    </Flex>
  );
};

export default AddFilter;
