import {
  Accessor,
  createEffect,
  JSX,
  Setter,
  Show,
  splitProps,
  untrack,
  type Component,
} from 'solid-js';
import Box from './Box';
import GW2Coin from '../gw2/GW2Coin';

export type CoinInputProps = {
  value?: Accessor<number>;
  onInput?: Setter<number>;
  placeholder?: string;
} & JSX.HTMLAttributes<HTMLDivElement>;

const inputRgx = /<span class="gwCoinVal">.*?<\/span>|^\d+$/g;

export const CoinInput: Component<CoinInputProps> = (props) => {
  const [{ value, placeholder, onInput }, rest] = splitProps(props, [
    'value',
    'placeholder',
    'onInput',
  ]);

  let el;
  let position = 0;
  const savePosition = () => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const clonedRange = range.cloneRange();
    clonedRange.selectNodeContents(el);
    clonedRange.setEnd(range.endContainer, range.endOffset);

    position = clonedRange.toString().length;
  };

  const createRange = (node, targetPosition) => {
    const range = document.createRange();
    range.selectNode(node);
    range.setStart(node, 0);

    let pos = 0;
    const stack = [node];
    while (stack.length > 0) {
      const current = stack.pop();

      if (current.nodeType === Node.TEXT_NODE) {
        const len = current.textContent.length;
        if (pos + len >= targetPosition) {
          range.setEnd(current, targetPosition - pos);
          return range;
        }
        pos += len;
      } else if (current.childNodes && current.childNodes.length > 0) {
        for (let i = current.childNodes.length - 1; i >= 0; i--) {
          stack.push(current.childNodes[i]);
        }
      }
    }

    // The target position is greater than the
    // length of the contenteditable element.
    range.setEnd(node, node.childNodes.length);
    return range;
  };

  const setPosition = () => {
    const range = createRange(el, position);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    selection.collapseToEnd();
  };

  const fixupPosition = (numStr) => {
    const oldVal = String(untrack(value)).length;
    const oldCommas = Math.ceil(Math.max(0, oldVal - 7) / 3) - 1;
    const newCommas = Math.ceil(Math.max(0, numStr.length - 7) / 3) - 1;
    const diff = newCommas - oldCommas;
    if (position > 1) {
      position += diff;
    }
  };

  createEffect(() => {
    value();
    setPosition();
  });

  const cb: JSX.EventHandler<HTMLDivElement, InputEvent> = (e) => {
    savePosition();
    const { target } = e;
    const input = target.innerHTML;
    const matches = input.match(inputRgx);
    if (!matches || !matches.length) {
      target.innerHTML = '';
      onInput(null);
      return;
    }
    if (!matches[0].includes('</span>')) {
      target.innerHTML = '';
    }
    const numStr = matches.map((str) => str.replace(/\D/g, '')).join('');
    const val = Number(numStr);
    fixupPosition(numStr);

    // null check done at init site
    onInput(val);
  };

  return (
    <Box
      ref={el}
      contenteditable='true'
      bg='$container'
      border='none'
      borderRadius='4px'
      outline='0 solid $accentBlue'
      _focus={{
        border: 'none',
        outline: '2px solid $accentBlue',
        bg: 'transparent',
      }}
      transition='all 0.04s ease-in-out'
      padding='8px 11px'
      _empty={{
        _after: {
          color: 'var(--hope-colors-neutral9)',
          content: placeholder,
        },
      }}
      {...(onInput ? { onInput: cb } : {})}
      {...rest}
    >
      <Show when={value()}>{(val) => <GW2Coin value={val} padded />}</Show>
    </Box>
  );
};
