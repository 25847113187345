import {
  ApiResponse,
  EndpointType,
  SupportedEndpoint,
} from '../workers/cache.worker';
import { createPromiseWithResolvers } from './util';

export default class Gw2Worker {
  private nextRequestId = 0;
  private inflight: Record<
    number,
    PromiseWithResolvers<EndpointType<SupportedEndpoint>[]>
  > = {};

  constructor(private cacheWorker: MessagePort) {
    this.cacheWorker.onmessage = this.handleMessage.bind(this);
  }

  /** Handle incoming messages from the cache.worker */
  private handleMessage({ data }: MessageEvent<ApiResponse<SupportedEndpoint>>) {
    const { resolve, reject } = this.inflight[data.requestId];

    if (data.data && data.data.length) {
      resolve(data.data);
    } else {
      reject();
    }

    delete this.inflight[data.requestId];
  }

  request<Endpoint extends SupportedEndpoint>(
    endpoint: Endpoint,
    ids: number[]
  ): Promise<EndpointType<Endpoint>[]> {
    const requestId = this.nextRequestId;
    this.nextRequestId += 1;

    // create promise that will be resolved in `handleMessage`
    const promise = createPromiseWithResolvers<EndpointType<Endpoint>[]>();
    this.inflight[requestId] = promise;

    this.cacheWorker.postMessage({
      requestId,
      endpoint,
      ids,
    });

    return promise.promise;
  }
}
