import {
  Alert,
  Box,
  Container,
  Heading,
  ListItem,
  OrderedList,
  Text,
  Tooltip,
  VStack,
} from '@hope-ui/solid';
import { Component, createSignal } from 'solid-js';

import { BsFileEarmarkArrowDown as DownloadIcon } from 'solid-icons/bs';

import { Button, Link } from '../components';
import IndexLayout from '../layouts/IndexLayout';

export const GettingStarted: Component = () => {
  const [installation, setInstallation] = createSignal<'steam' | 'launcher'>(
    'launcher'
  );

  const installationLocation = () => {
    if (installation() === 'steam') {
      return 'C:/Program Files (x86)/Steam/steamapps/common/Guild Wars 2';
    }

    return 'C:/Program Files/Guild Wars 2';
  };

  const GW2InstallLocation: Component<{ location?: string }> = (props) => {
    const location = () => (props.location ? `/${props.location}` : '');
    const [copied, setCopied] = createSignal(false);

    const clipboard = () => {
      if (copied()) return;
      setCopied(true);

      navigator.clipboard.writeText(
        location().split('/').slice(0, -1).join('/')
      );

      setTimeout(() => setCopied(false), 1000);
    };

    return (
      <Tooltip
        label={
          copied()
            ? 'Copied to clipboard!'
            : `${installationLocation()}${location()}`
        }
        closeDelay={copied() ? 1000 : 0}
        closeOnClick={false}
      >
        <Text as='code' cursor='pointer' color='white' onClick={clipboard}>
          {`<GW2 Root>`}
          {location()}
        </Text>
      </Tooltip>
    );
  };

  return (
    <IndexLayout>
      <Container
        display='flex'
        flexDirection='column'
        gap='$6'
        mb='$16'
        px='$4'
        css={{
          code: {
            display: 'inline-block',
            background: '$whiteAlpha4',
            borderRadius: '5px',
            fontFamily: '$mono',
            px: '4px',
          },
        }}
      >
        <Heading fontSize='$3xl' pb='$2' borderBottom='2px solid $accentBlue'>
          Getting Started
        </Heading>

        <Box as='section'>
          <Heading level={3} fontSize='$xl' color='$accentBlue'>
            What is this?
          </Heading>
          <Text>
            DRF (Drop Research Facilities) is a tool suite that allows you to
            collect in-game drop data from your Guild Wars 2 client. All data is
            collected in real-time, meaning you won't need to wait for the Guild
            Wars 2 API when you want to see updates to your account. We have
            built tooling around this so you can see real-time session
            statistics for the drops you are collecting and much more. If you
            want to know more that DRF can do, you can see more information
            about this <Link href='/#features'>here</Link>.
          </Text>
        </Box>

        <Box as='section'>
          <Heading level={3} fontSize='$xl' color='$accentBlue'>
            How does it work?
          </Heading>
          <Text>
            Installing an addon will allow you to start collecting real-time
            drop data directly from your Guild Wars 2 client. The addon reports
            directly to our servers so that you don't need to worry about
            storing or processing the data. When you open our website dashboard,
            we connect you to a websocket so that you can see the data that is
            collected.
          </Text>
        </Box>

        <Box as='section'>
          <Heading level={3} fontSize='$xl' color='$accentBlue'>
            Installation
          </Heading>
          <VStack spacing='$3' alignItems='start'>
            <Text>
              First, you will need a way to load the DRF addon when you start
              Guild Wars 2. Two methods for this would be to use{' '}
              <code>ArcDPS</code> or <code>Gw2Al</code> to load the addon for
              you. If you use addon manager for other addons, you will already
              have Gw2Al installed.
            </Text>
            <Alert
              display='flex'
              gap={{ '@initial': '$2', '@md': '$4' }}
              flexDirection={{ '@initial': 'column', '@md': 'row' }}
              alignItems={{ '@initial': 'start', '@md': 'center' }}
            >
              <Text>Download the DRF addon dll here:</Text>
              <Link href='https://update.drf.rs/drf.dll' noExternalIcon>
                <Button leftIcon={<DownloadIcon />} p='$1_5' fontSize='$sm'>
                  Download
                </Button>
              </Link>
            </Alert>
            <Box d='flex' gap='$4' borderBottom='2px solid $containerOutline'>
              <Box
                py='$2'
                px='$1'
                borderBottom='2px solid'
                borderBottomColor={
                  installation() === 'launcher' ? '$accentBlue' : 'transparent'
                }
                onClick={() => setInstallation('launcher')}
                cursor='pointer'
                mb='-2px'
                transition='all ease-in-out 0.2s'
              >
                GW2 Launcher
              </Box>
              <Box
                py='$2'
                px='$1'
                borderBottom='2px solid'
                borderBottomColor={
                  installation() === 'steam' ? '$accentBlue' : 'transparent'
                }
                onClick={() => setInstallation('steam')}
                cursor='pointer'
                mb='-2px'
                transition='all ease-in-out 0.2s'
              >
                Steam
              </Box>
            </Box>
            <Box
              px='$2'
              py='$1'
              borderLeft='2px solid $containerOutline'
              background='$whiteAlpha2'
            >
              Default Guild Wars 2 location:{' '}
              <code>{installationLocation()}</code>
            </Box>
            <Box>
              <Heading level={4} color='$accentBlue'>
                ArcDPS Method
              </Heading>
              <Link
                href='https://www.deltaconnected.com/arcdps/'
                target='_blank'
                fontSize='$sm'
                color='$textMuted'
              >
                https://www.deltaconnected.com/arcdps/
              </Link>
              <OrderedList spacing='$0_5'>
                <ListItem>
                  Put the addon into the same folder that ArcDPS is located in.
                  <br />
                  <Text as='span' fontSize='$sm' color='$whiteAlpha10'>
                    This is usually at{' '}
                    <GW2InstallLocation location='d3d11.dll' />, or{' '}
                    <GW2InstallLocation location='addons/arcdps/gw2addon_arcdps.dll' />
                    .
                  </Text>
                </ListItem>
                <ListItem>Restart your Guild Wars 2 client.</ListItem>
              </OrderedList>
            </Box>
            <Box>
              <Heading level={4} color='$accentBlue'>
                Gw2Al Method
              </Heading>
              <Link
                href='https://github.com/Draeggiar/GW2-Addon-Manager'
                target='_blank'
                fontSize='$sm'
                color='$textMuted'
              >
                https://github.com/Draeggiar/GW2-Addon-Manager
              </Link>
              <OrderedList spacing='$0_5'>
                <ListItem>
                  Put the addon into{' '}
                  <GW2InstallLocation location='addons/drf' />.
                  <Text fontSize='$sm' color='$whiteAlpha10'>
                    You will need to create the <code>drf</code> folder
                    yourself.
                  </Text>
                </ListItem>
                <ListItem>
                  Rename the addon file you downloaded to{' '}
                  <code>gw2addon_drf.dll</code>.
                  <Text fontSize='$sm' color='$whiteAlpha10'>
                    If the dll part does not show up when renaming the file,
                    then just name it <code>gw2addon_drf</code>.
                  </Text>
                </ListItem>
                <ListItem>Restart your Guild Wars 2 client.</ListItem>
              </OrderedList>
            </Box>
          </VStack>
        </Box>
        <Box as='section'>
          <Heading level={3} color='$accentBlue' fontSize='$xl'>
            Usage
          </Heading>
          <Text>
            After you have installed the addon head over to the{' '}
            <Link href='/dashboard'>dashboard</Link> and start picking up items
            in-game! You will see live inventory changes in the live tracker
            section of the dashboard.
          </Text>
        </Box>
      </Container>
    </IndexLayout>
  );
};

export default GettingStarted;
