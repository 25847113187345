import { Flex, Icon, Tooltip } from '@hope-ui/solid';
import type { HopeProps } from '@hope-ui/solid';
import { Show, splitProps } from 'solid-js';
import type { JSX, Component, ComponentProps } from 'solid-js';
import type { IconTypes } from 'solid-icons';

export type MenuButtonProps = {
  selected?: boolean;
  children?: JSX.Element;
  icon?: IconTypes;
  collapsed?: boolean;
} & HopeProps &
  ComponentProps<'div'>; // Is this the only only way to add the normal props back to the object?

export const MenuButton: Component<MenuButtonProps> = (props) => {
  const [local, rest] = splitProps(props, ['children', 'icon', 'collapsed']);

  return (
    <Tooltip
      label={local.children}
      placement='right'
      disabled={!local.collapsed}
    >
      <Flex
        p='$2'
        cursor='pointer'
        borderRadius='5px'
        bg={props.selected ? '$accentBlue' : ''}
        userSelect='none'
        alignItems='center'
        justifyContent={local.collapsed ? 'center' : 'flex-start'}
        _hover={{
          bg: props.selected ? '' : '$sidebarButtonHover',
        }}
        transition='all 0.2s ease-in-out'
        {...rest}
      >
        <Show when={local.icon}>
          <Icon
            as={local.icon}
            mr={props.collapsed ? '' : '$2'}
            mt='1px'
            boxSize='1.3em'
          />
        </Show>
        <Show when={!local.collapsed}>{local.children}</Show>
      </Flex>
    </Tooltip>
  );
};
