import { Box, Icon } from '@hope-ui/solid';
import { Motion } from '@motionone/solid';
import type { Component } from 'solid-js';

import AppIcon from '../../assets/appIcon.svg';

export type LoadingScreenProps = {
  noAnimation?: boolean;
};

export const LoadingScreen: Component<LoadingScreenProps> = (props) => {
  return (
    <Box
      as={Motion.div}
      d='flex'
      pos='absolute'
      left={0}
      top={0}
      h='100vh'
      w='100vw'
      justifyContent='center'
      alignItems='center'
      bg='#050505'
      zIndex={10}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
    >
      <Motion.div
        animate={{
          opacity: props.noAnimation ? 1 : [1, 0.1, 1],
        }}
        transition={{
          repeat: Infinity,
          duration: 1.5,
        }}
      >
        <Icon as={AppIcon} boxSize='$40' />
      </Motion.div>
    </Box>
  );
};

export default LoadingScreen;
