import { globalCss, HopeProvider, HopeThemeConfig } from '@hope-ui/solid';
import { Component, Show } from 'solid-js';
import { Router, Route, Routes } from 'solid-app-router';

import { componentStyles, darkTheme } from './theme';

import Index from './pages/Index';
import DashboardHome from './pages/dashboard/DashboardHome';
import LiveTracker from './pages/dashboard/LiveTracker';
import Settings from './pages/dashboard/user/Settings';
import OAuthCallback from './pages/auth/OauthCallback';

import { GW2ApiProvider } from './components/contextProviders/GW2Api';
import { TooltipProvider } from './directives/tooltip';
import { AuthProvider } from './userstore';
import { ChartProvider } from './components/chart/ChartContext';
import DevMenu from './components/dev/DevMenu';
import GettingStarted from './pages/GettingStarted';
import { createLocalSignal } from './localstorage';
import { CookieBar, DevGate } from './components';
import LegalNotice from './pages/LegalNotice';
import PrivacyPolicy from './pages/PrivacyPolicy';
import DataProvider from './context/DataContext';

const config: HopeThemeConfig = {
  initialColorMode: 'dark',
  darkTheme,
  components: {
    ...componentStyles,
  },
};

const App: Component = () => {
  const [cookiesAcknowledged, setCookiesAcknowledged] = createLocalSignal(
    'cookies-acknowledged'
  );

  const applyGlobalCss = globalCss({
    '*': {
      fontFamily: '$sans',
    },
    '.item-view-shuffle-enter-active, .item-view-shuffle-exit-active': {
      transition: 'opacity 0.3s, transform 0.3s',
    },
    '.item-view-shuffle-enter, .item-view-shuffle-exit-to': {
      transform: 'translateX(10px)',
      opacity: 0,
    },
    '.item-view-shuffle-enter': {
      transform: 'translateX(-10px)',
    },
  });

  applyGlobalCss();

  return (
    <HopeProvider config={config}>
      <AuthProvider>
        <GW2ApiProvider>
          <DataProvider>
            <ChartProvider>
              <TooltipProvider>
                <Router>
                  <Routes>
                    <Route path='/' element={<Index />} />
                    <Route
                      path='/getting-started'
                      element={<GettingStarted />}
                    />
                    <Route path='/legal-notice' element={<LegalNotice />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route path='/auth'>
                      <Route
                        path='/oauthcallback'
                        element={<OAuthCallback />}
                      />
                    </Route>
                    <Route path='/dashboard'>
                      <Route path='/' element={<DashboardHome />} />
                      <Route path='/livetracker/*' element={<LiveTracker />} />
                      <Route path='/user'>
                        <Route path='/settings' element={<Settings />} />
                      </Route>
                    </Route>
                  </Routes>

                  <DevGate>
                    <DevMenu />
                  </DevGate>

                  <Show when={!cookiesAcknowledged()}>
                    <CookieBar
                      onAcknowledged={() => setCookiesAcknowledged(true)}
                    />
                  </Show>
                </Router>
              </TooltipProvider>
            </ChartProvider>
          </DataProvider>
        </GW2ApiProvider>
      </AuthProvider>
    </HopeProvider>
  );
};

export default App;
