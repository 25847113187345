import '@fontsource/karla/300.css';
import '@fontsource/karla/400.css';
import '@fontsource/karla/500.css';
import '@fontsource/karla/600.css';
import '@fontsource/karla/800.css';

import { render } from 'solid-js/web';
import './index.css';

import App from './App';

render(() => <App />, document.getElementById('root') as HTMLElement);
