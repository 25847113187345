import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  CloseButton,
  FormControl,
  FormLabel,
  Heading,
  Text,
  VStack,
} from '@hope-ui/solid';
import { onCleanup, onMount, Show, useContext } from 'solid-js';
import { createStore } from 'solid-js/store';
import { createLocalStore } from '../../localstorage';
import { AuthContext } from '../../userstore';
import { Box, Button, Flex, Input } from '../global';

export const DevMenu = () => {
  let ref: HTMLDivElement;
  let form!: HTMLFormElement;

  const [, { getSessionSignal }] = useContext(AuthContext);

  const [devMenuSettings, setDevMenuSettings] = createLocalStore(
    'devMenuSettings',
    {
      accordion: -1,
      chaosMonkey: {
        character: '',
        duration: 60,
        drops: 120,
      },
    }
  );

  const [devWindow, setDevWindow] = createStore({
    open: false,
    position: { x: 0, y: 0 },
    mousePosition: { x: 0, y: 0 },
    dragOffset: { x: 0, y: 0 },
    dragging: false,
    dragTimer: undefined,
  });

  const onMouseMove = (e: MouseEvent) => {
    if (devWindow.dragging) {
      setDevWindow('position', {
        x: e.x - devWindow.dragOffset.x,
        y: e.y - devWindow.dragOffset.y,
      });
    }
  };

  const onMouseUp = () => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);

    if (devWindow.dragTimer) clearTimeout(devWindow.dragTimer);
    window.localStorage.setItem(
      'devWindowPosition',
      `${devWindow.position.x},${devWindow.position.y}`
    );

    setDevWindow('dragging', false);
  };

  const onMouseDown = (e: MouseEvent) => {
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);

    const timer = setTimeout(() => {
      setDevWindow('dragOffset', {
        x: e.x - Number(ref.style.left.substring(0, ref.style.left.length - 2)),
        y: e.y - Number(ref.style.top.substring(0, ref.style.top.length - 2)),
      });

      setDevWindow('dragging', true);
    }, 100);

    setDevWindow('dragTimer', timer);
  };

  const checkIfShouldBeOpen = () => {
    if (window.localStorage.getItem('devWindowState')) {
      setDevWindow('open', true);
      return;
    }

    setDevWindow('open', false);
  };

  const toggleDevWindow = () => {
    const previousState = window.localStorage.getItem('devWindowState');

    if (previousState === null) {
      window.localStorage.setItem('devWindowState', 'open');
      setDevWindow('open', true);
      return;
    }

    window.localStorage.removeItem('devWindowState');
    setDevWindow('open', false);
  };

  onMount(() => {
    checkIfShouldBeOpen();
    window.addEventListener('toggleDevWindow', toggleDevWindow);

    const savedPosition = window.localStorage.getItem('devWindowPosition');

    if (savedPosition) {
      const pos = savedPosition.split(',');
      setDevWindow('position', { x: Number(pos[0]), y: Number(pos[1]) });
    }
  });

  onCleanup(() => {
    window.removeEventListener('toggleDevWindow', toggleDevWindow);
  });

  const handlesubmit = async () => {
    const params = new URLSearchParams(new FormData(form) as any);
    if (params.has('jitter')) {
      params.set('jitter', params.get('jitter') === 'on' ? 'true' : 'false');
    }
    await fetch('https://chaos.drf.rs/', {
      mode: 'cors',
      method: 'POST',
      headers: {
        Authorization: `${getSessionSignal()()}`,
      },
      body: params,
    });
  };

  return (
    <Show when={devWindow.open}>
      <Box
        ref={ref}
        position='fixed'
        d='flex'
        p='$4'
        w='320px'
        maxH='440px'
        flexDirection='column'
        gap='$3'
        bg='rgba(0,0,0,0.8)'
        borderRadius='5px'
        border='1px solid $containerOutline'
        css={{
          backdropFilter: 'blur(4px)',
        }}
        style={{
          top: `${devWindow.position.y}px`,
          left: `${devWindow.position.x}px`,
        }}
        overflowY='auto'
      >
        <CloseButton
          pos='absolute'
          right='0'
          top='0'
          w='28px'
          h='28px'
          borderRadius='0 0 0 5px'
          _focus={{ boxShadow: 'none' }}
          color='$whiteAlpha8'
          onclick={toggleDevWindow}
        />
        <Flex
          onMouseDown={onMouseDown}
          justifyContent='center'
          m='-1rem'
          p='1rem'
        >
          <Heading userSelect='none'>Developer Menu</Heading>
        </Flex>
        <Accordion
          index={devMenuSettings.accordion}
          onChange={(index: number) => setDevMenuSettings('accordion', index)}
        >
          <AccordionItem>
            <AccordionButton
              _focus={{
                boxShadow: 'none',
              }}
            >
              <Text flex={1} textAlign='left'>
                Chaos Monkey
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Box d='flex' flexDirection='column'>
                <form ref={form} onsubmit={(e) => e.preventDefault()}>
                  <VStack spacing='$2'>
                    <FormControl>
                      <FormLabel>Character</FormLabel>
                      <Input
                        name='character'
                        required
                        placeholder='Character'
                        value={devMenuSettings.chaosMonkey.character}
                        onInput={(e: any) =>
                          setDevMenuSettings(
                            'chaosMonkey',
                            'character',
                            e.target.value
                          )
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Duration</FormLabel>
                      <Input
                        name='duration'
                        placeholder='Duration'
                        type='number'
                        value={devMenuSettings.chaosMonkey.duration}
                        onInput={(e: any) =>
                          setDevMenuSettings(
                            'chaosMonkey',
                            'duration',
                            e.target.value
                          )
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Drops</FormLabel>
                      <Input
                        name='drops'
                        placeholder='Drops'
                        type='number'
                        value={devMenuSettings.chaosMonkey.drops}
                        onInput={(e: any) =>
                          setDevMenuSettings(
                            'chaosMonkey',
                            'drops',
                            e.target.value
                          )
                        }
                      />
                    </FormControl>
                    <label>
                      <input name='jitter' type='checkbox' role='switch' />{' '}
                      Jitter
                    </label>
                    {/* <Switch defaultChecked LabelPlacement="end" type="checkbox" name="jitter">Jitter</Switch> */}
                    <Button onClick={handlesubmit}>Generate Drops</Button>
                  </VStack>
                </form>
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Show>
  );
};

export default DevMenu;
