import {
  Box,
  Container,
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from '@hope-ui/solid';

import { Image, Link } from '../components';
import IndexLayout from '../layouts/IndexLayout';

import EmailImage from '../assets/legal/email.png';
import NumberImage from '../assets/legal/number.png';

export const PrivacyPolicy = () => {
  return (
    <IndexLayout>
      <Container d='flex' flexDirection='column' gap='$4' mb='$16' px='$4'>
        <Heading
          level={1}
          size='3xl'
          pb='$2'
          borderBottom='2px solid $accentBlue'
        >
          Privacy Policy
        </Heading>

        <Text>Last updated: August 27, 2022</Text>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Human version</Heading>
          <UnorderedList>
            <ListItem>
              We use cookies and your browser's{' '}
              <Link
                href='https://en.wikipedia.org/wiki/Web_storage'
                rel='external nofollow noopener'
                target='_blank'
              >
                local storage
              </Link>{' '}
              to keep you logged into your account.
            </ListItem>
            <ListItem>
              We do not know to your password or email address. All
              authentication is done via{' '}
              <Link
                href='https://gw2auth.com/privacy-policy'
                rel='external nofollow noopener'
                target='_blank'
              >
                GW2Auth
              </Link>
              .
            </ListItem>
            <ListItem>
              We keep web server log files around for 14 days to detect abuse.
            </ListItem>
          </UnorderedList>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Personal Identifiable Information</Heading>
          <Text>
            We may collect personal identification information from Users in a
            variety of ways in connection with activities, services, features or
            resources we make available on our Site. Users may visit our Site
            anonymously. We will collect personal identification information
            from Users only if they voluntarily submit such information to us.
            Users can always refuse to supply personally identification
            information, except that it may prevent them from engaging in
            certain Site related activities.
          </Text>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Non-personal Identifiable Information</Heading>
          <Text>
            We may collect non-personal identification information about Users
            whenever they interact with our Site. Non-personal identification
            information may include the browser name, the type of computer and
            technical information about Users means of connection to our Site,
            such as the operating system and the Internet service providers
            utilized and other similar information.
          </Text>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Cookies</Heading>
          <Text>
            Our Site may use "cookies" and "local storage" to enhance user
            experience. User's web browser places cookies on their hard drive
            for record-keeping purposes and sometimes to track information about
            them. User may choose to set their web browser to refuse cookies, or
            to alert you when cookies are being sent. If they do so, note that
            some parts of the Site may not function properly.
          </Text>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>How we use collected information</Heading>
          <Text>
            DRF may collect and use personal information for the following
            purposes:
          </Text>
          <UnorderedList>
            <ListItem>
              <strong>To run and operate our Site</strong>
              <br />
              We may need your information to display content on the Site
              correctly.
            </ListItem>
            <ListItem>
              <strong>To improve customer service</strong>
              <br />
              The information you provide helps us respond to your customer
              service requests and support needs more efficiently.
            </ListItem>
            <ListItem>
              <strong>To personalize user experience</strong>
              <br />
              We may use information in the aggregate to understand how our
              users as a group use the services and resources provided on our
              Site.
            </ListItem>
            <ListItem>
              <strong>To improve our Site</strong>
              <br />
              We may use feedback you provide to improve our products and
              services.
            </ListItem>
          </UnorderedList>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>How we protect your information</Heading>
          <Text>
            We adopt appropriate data collection, storage and processing
            practices and security measures to protect against unauthorized
            access, alteration, disclosure or destruction of your personal
            information, username, and data stored on our Site.
          </Text>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Sharing your personal information</Heading>
          <Text>
            We do not sell, trade, or rent personal identification information
            to others.
          </Text>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Children's Information</Heading>
          <Text mb='$2'>
            We encourage parents and guardians to observe, participate in,
            and/or monitor and guide their online activity.
          </Text>
          <Text>
            Drop Research Facility does not knowingly collect any Personal
            Identifiable Information from children under the age of 13. If you
            think that your child provided this kind of information on our
            website, we strongly encourage you to contact us immediately and we
            will do our best efforts to promptly remove such information from
            our records.
          </Text>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Third party websites</Heading>
          <Text>
            Users may find advertising or other content on our Site that link to
            the Sites and services of our partners, suppliers, advertisers,
            sponsors, licencors and other third parties. We do not control the
            content or links that appear on these Sites and are not responsible
            for the practices employed by websites linked to or from our Site.
            In addition, these Sites or services, including their content and
            links, may constantly be changing. These Sites and services may have
            their own privacy policies and customer service policies. Browsing
            and interaction on any other webSite, including webSites which have
            a link to our Site, is subject to that webSite's own terms and
            policies.
          </Text>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='xl'>GW2Auth</Heading>
          <Text>
            When signing up/logging in, you will be redirected to GW2Auth for
            authentication. Their privacy policy can be viewed at{' '}
            <Link
              href='https://gw2auth.com/privacy-policy'
              rel='external nofollow noopener'
              target='_blank'
            >
              https://gw2auth.com/privacy-policy
            </Link>
          </Text>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Changes to this privacy policy</Heading>
          <Text>
            DRF has the discretion to update this privacy policy at any time.
            When we do, we will post a notification on the main page of our
            Site. We encourage Users to frequently check this page for any
            changes to stay informed about how we are helping to protect the
            personal information we collect. You acknowledge and agree that it
            is your responsibility to review this privacy policy periodically
            and become aware of modifications.
          </Text>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Your acceptance of this terms</Heading>
          <Text>
            By using this Site, you signify your acceptance of this policy. If
            you do not agree to this policy, please do not use our Site. Your
            continued use of the Site following the posting of changes to this
            policy will be deemed your acceptance of those changes.
          </Text>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Contact</Heading>
          <Text>
            If you have any questions about this Privacy Policy, the practices
            of this Site, or your dealings with this Site, please contact us.
          </Text>
          <Box>
            <Flex alignItems='center' gap='$1'>
              <Text>Email:</Text>
              <Box mb='-3px'>
                <Image src={EmailImage} crossOrigin='anonymous' />
              </Box>
            </Flex>
            <Flex alignItems='center' gap='$1'>
              <Text>Phone:</Text>
              <Box mb='-2px'>
                <Image src={NumberImage} crossOrigin='anonymous' />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </IndexLayout>
  );
};

export default PrivacyPolicy;
