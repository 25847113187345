import {
  createResource,
  Show,
  splitProps,
  Suspense,
  useContext,
} from 'solid-js';
import type { Component } from 'solid-js';
import type { DeepReadonly } from 'solid-js/store';
import { Box, Skeleton, Text, HopeProps } from '@hope-ui/solid';
import { Image } from '../global';

import GW2IconTooltip from './GW2ItemTooltip';
import { tooltip } from '../../directives/tooltip';
import DropFactory, { AsyncItemData, ItemData } from '../../lib/drf/drop';
import { getItemAbsValue } from '../../lib/drf/summary';
import { DataContext } from '../../context/DataContext';
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
tooltip; // avoid early treeshaking

export type GW2ItemProps = {
  item: DeepReadonly<AsyncItemData | ItemData>;
} & HopeProps;

const GW2Item: Component<GW2ItemProps> = (props) => {
  const { highlightValue } = useContext(DataContext);
  const [itemProps, rest] = splitProps(props, ['item']);

  // const [isNew, setNew] = createSignal(true);

  // const hover = () => {
  //   setNew(false);
  // };

  const [itemRes] = createResource(
    () => itemProps.item,
    (i) => {
      if ('then' in (i.api ?? {}))
        return DropFactory.awaitItemData(i as AsyncItemData);
      return Promise.resolve(i as ItemData);
    }
  );

  const isHighlighted = () => highlightValue() && getItemAbsValue(itemRes()) > highlightValue();
  const rarityColor = () => itemRes().amount >= 0
    ? `var(--hope-colors-${itemRes().api.rarity.toLowerCase()})`
    : 'red';

  return (
    <Suspense fallback={<Skeleton boxSize='$12' borderRadius={0} />}>
      <Show when={itemRes()}>
        {(item) => (
          <div use:tooltip={() => <GW2IconTooltip item={item} />}>
            <Box pos='relative' boxSize='$12' {...rest}>
              <Box
                position='absolute' width='$full' height='$full'
                boxShadow={!isHighlighted() && `inset 0 0 0 2px ${rarityColor()}`}
                background={
                  item.amount < 0
                    ? 'radial-gradient(circle at center, #FF0000CC 0%, transparent 125%)'
                    : 'none'
                }
                opacity={0.7}
              />
              <Show when={isHighlighted()}>
                {/** highlight border */}
                <Box
                  position='absolute' width='$full' height='$full'
                  background={`0 0 / 200% linear-gradient(45deg, transparent, ${rarityColor()}, transparent, ${rarityColor()})`}
                  zIndex={1}
                  clipPath='polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 2px, 2px 2px, 2px calc(100% - 2px), calc(100% - 2px) calc(100% - 2px), calc(100% - 2px) 2px, 0 2px)'
                  // clipPath='polygon(0 0, 100% 0, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%, 0 2px, 2px 2px, 2px calc(100% - 2px), calc(100% - 16px) calc(100% - 2px), calc(100% - 2px) calc(100% - 16px), calc(100% - 2px) 2px, 0 2px)'
                />
                {/** highlight blur */}
                <Box
                  position='absolute' width='$full' height='$full'
                  zIndex={-1}
                  opacity={.7}
                  boxShadow={`0 0 16px -2px ${rarityColor()}`}
                />
              </Show>
              <Image
                src={item.api.icon
                  ?.replace(
                    'https://render.guildwars2.com/file/',
                    'https://icons-gw2.darthmaim-cdn.com/'
                  )
                  .replace('.png', '-64px.png')}
                crossOrigin='anonymous'
                // clipPath={isHighlighted() && 'polygon(0 0, 100% 0, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%)'}
              />
              <Show when={item.amount}>
                <Text
                  pos='absolute'
                  top='1px'
                  right='5px'
                  textShadow='0 0 3px black'
                  fontFamily='$menomonia'
                  lineHeight='1.1rem'
                >
                  {item.amount}
                </Text>
              </Show>
              {/* <Show when={isHighlighted()}>
                <Icon
                  pos='absolute'
                  top='-6px'
                  left='-6px'
                  as={AiFillStar}
                  width={16}
                  color={rarityColor()}
                  style={{ filter: 'drop-shadow(0 0 5px black) saturate(.5)' }}
                />
              </Show> */}
            </Box>
          </div>
        )}
      </Show>
    </Suspense>
  );
};

export default GW2Item;
