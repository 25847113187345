import {
  createContext,
  Component,
  createResource,
  Context,
  onCleanup,
  Accessor,
  Resource,
} from 'solid-js';
import { DeepReadonly } from 'solid-js/store';

import {
  cleanupLocalstorage,
  createLocalSignal,
  createLocalStore,
} from './localstorage';

export const AuthContext: Context<StoreType> = createContext();

export interface Gw2Account {
  account_id: number | null;
  display_name: string;
  api_token: string;
}

export interface UserSettings {
  name: string;
  public: boolean;
  apikey: string | null;
  gw2_accounts: Gw2Account[];
}

type State = { user: UserSettings | undefined; loaded: boolean | undefined };
type StoreType = [DeepReadonly<State>, StoreFuncs];

type StoreFuncs = {
  getSessionSignal(): Accessor<string>;
  setSessionToken(token: string | undefined): void;
  getSettings(): void;
  getCharacterResource(): Resource<string[]>;
  regenerateApiKey(): Promise<void>;
  setUsername(username: string): Promise<void>;
  togglePublic(): Promise<void>;
  logout(): void;
};

export const AuthProvider: Component = (props) => {
  const [state, setState] = createLocalStore<State>('state', {
    user: undefined,
    loaded: undefined,
  });

  const [session, setSession] = createLocalSignal<string>('session');

  onCleanup(() => cleanupLocalstorage('session'));

  const [settings] = createResource(session, (s) => {
    setState('loaded', undefined);
    fetch('https://api.drf.rs/me/settings', {
      headers: {
        Authorization: s,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Invalid Response Status: ${res.status} (${res})`);
        }
        return res.json();
      })
      .then((user) => setState({ user, loaded: true }))
      .catch(() => setState({ user: undefined, loaded: false }));
  });
  const [characters] = createResource<string[], string>(
    session,
    (s) =>
      fetch('https://api.drf.rs/me/characters', {
        headers: {
          Authorization: s,
        },
      })
        .then((res) => {
          if (res.status !== 200) {
            throw new Error(`Invalid Response Status: ${res.status} (${res})`);
          }
          return res.json();
        })
        .then((chars: string[]) => chars.sort())
        .catch(() => []),
    {
      initialValue: [],
    }
  );

  const store: StoreType = [
    state,
    {
      getSessionSignal() {
        return session;
      },
      setSessionToken(token) {
        setSession(token);
      },
      getSettings() {
        return settings();
      },
      getCharacterResource() {
        return characters;
      },
      async regenerateApiKey() {
        try {
          const response = await fetch('https://api.drf.rs/me/regenerate', {
            method: 'POST',
            headers: {
              Authorization: session(),
            },
          });

          if (!response.ok) throw new Error();

          const newApiKey = await response.text();

          setState({ user: { ...state.user, apikey: newApiKey } });
        } catch (e) {
          throw new Error('There was a problem regenerating your key.');
        }
      },
      async setUsername(username: string) {
        if (username.length < 3)
          throw new Error('Username should be more than 3 characters');

        const response = await fetch('https://api.drf.rs/me/settings', {
          method: 'PUT',
          headers: {
            Authorization: session(),
            'Content-Type': 'text/json',
          },
          body: JSON.stringify({ name: username, public: true }),
        });

        if (!response.ok) throw new Error(await response.text());

        setState({ user: { ...state.user, name: username } });
      },
      async togglePublic() {
        const response = await fetch('https://api.drf.rs/me/settings', {
          method: 'PUT',
          headers: {
            Authorization: session(),
            'Content-Type': 'text/json',
          },
          body: JSON.stringify({
            name: state.user.name,
            public: !state.user.public,
          }),
        });

        if (!response.ok) throw new Error(await response.text());

        setState({ user: { ...state.user, public: !state.user.public } });
      },
      logout() {
        fetch('https://api.drf.rs/auth/logout', {
          method: 'POST',
          headers: {
            Authorization: session(),
          },
        })
          .finally(() => setSession('invalid')) // setting this to null breaks reactivity
          .finally(() => setState({ user: undefined }));
      },
    },
  ];

  return (
    <AuthContext.Provider value={store}>{props.children}</AuthContext.Provider>
  );
};
