import { Component } from 'solid-js';
import { Box, BoxProps } from '../global';

import HeroWave from '../../assets/hero-wave.png';

export type DRFWaveProps = BoxProps;

export const DRFWave: Component<DRFWaveProps> = (props) => {
  return (
    <Box
      {...props}
      css={{
        background: `url("${HeroWave}")`,
        backgroundPosition: 'bottom',
        backgroundSize: 'cover',
      }}
    />
  );
};

export default DRFWave;
