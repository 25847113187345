import type { ButtonStyleConfig } from '@hope-ui/solid';

export const Button: ButtonStyleConfig = {
  baseStyle: {
    root: {
      bg: '$accentBlue',
      alignItems: 'center',
      borderRadius: '5px',
      outline: '0px solid transparent',
      '&:not(:disabled):hover': {
        bg: 'none',
        outline: '1px solid $accentBlue',
      },
      _focus: {
        outline: '2px solid $focusOutline',
        boxShadow: 'none',
      },
      _active: {
        bg: '$container !important',
      },
      transition: 'all 0.08s ease-in-out',
      fontFamily: '$sans',
      fontWeight: '$normal',
      variants: {
        variant: {
          small: {
            px: '$1_5',
            py: '$0_5',
            fontSize: '$sm',
          },
        },
      },
    },
  },
};

export default { Button };
