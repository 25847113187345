import { useContext } from 'solid-js';
import { Heading } from '@hope-ui/solid';
import { CoinInput } from '..';
import { DataContext } from '../../context/DataContext';

export const HighlightConfig = () => {
  const { highlightValue, setHighlightValue } = useContext(DataContext);

  return (
    <>
      <Heading fontWeight='$normal' borderBottom='1px solid $container' pb='$1'>
        Highlight Items
      </Heading>
      <CoinInput
        value={highlightValue}
        onInput={setHighlightValue}
        placeholder='Highlight Items Above Value...'
      />
    </>
  );
};

export default HighlightConfig;
