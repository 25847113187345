import { Box, HTMLHopeProps } from "@hope-ui/solid";
import { Component, JSX, Match, Switch, splitProps } from "solid-js";

export type ImageProps = HTMLHopeProps<'div', {
  src: string,
  crossOrigin?: JSX.HTMLCrossorigin
}>;

export const Image: Component<ImageProps> = (props) => {
  const [imageProps, boxProps] = splitProps(props, ['src', 'crossOrigin']);
  const hasBoxProps = () => Object.values(boxProps).length > 0;

  return (
    <Switch>
      <Match when={hasBoxProps()}>
        <Box {...boxProps}>
          <img {...imageProps} style={{ width: '100%', height: '100%' }}/>
        </Box>
      </Match>
      <Match when={true}>
        <img {...imageProps}/>
      </Match>
    </Switch>
  )
};
