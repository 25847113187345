import type { HopeProps } from '@hope-ui/solid';
import { ComponentProps, splitProps } from 'solid-js';
import type { Component } from 'solid-js';
import Box from './Box';

export type FlexProps = HopeProps & ComponentProps<'div'>;

export const Flex: Component<FlexProps> = (props) => {
  const [local, rest] = splitProps(props, ['children']);

  return (
    <Box display='flex' {...rest}>
      {local.children}
    </Box>
  );
};

export default Flex;
