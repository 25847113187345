import {
  createSignal,
  JSXElement,
  onCleanup,
  createContext,
  useContext,
} from 'solid-js';

interface TooltipContextValue {
  enter: any;
  leave: any;
  counter: any;
  setCounter: any;
  cleanup: any;
  init: any;
}

declare module 'solid-js' {
  namespace JSX {
    interface Directives {
      tooltip: JSX.Element;
    }
  }
}

export const TooltipContext = createContext<TooltipContextValue>({
  enter: () => {},
  leave: () => {},
  counter: () => {},
  setCounter: () => {},
  cleanup: () => {},
  init: () => {},
});

export const TooltipProvider = (props: { children: any }) => {
  const [values, setValues] = createSignal();
  const [counter, setCounter] = createSignal(0);

  const tooltipcontainer: HTMLDivElement = (
    <div
      style={{
        position: 'fixed', // make it relative to viewport
        'pointer-events': 'none',
        transition: 'opacity 0.1s ease-in',
        'z-index': 99,
      }}
    >
      {values() as JSXElement}
    </div>
  ) as HTMLDivElement;

  const calculatePosition = (e: MouseEvent, target: HTMLElement) => {
    const { width, height } = target.getBoundingClientRect();

    const offset = 30;

    const viewportwidth = Math.min(
      window.innerWidth,
      document.documentElement.clientWidth
    );
    const viewportheight = Math.min(
      window.innerHeight,
      document.documentElement.clientHeight
    );

    // horizontal
    if (e.clientX + width > viewportwidth) {
      // would overflow right so flip to left
      if (e.clientX - width < 0) {
        // would overflow left so just let it overflow right instead (not enough space to display tooltip)
        tooltipcontainer.style.right = null; // make sure its not positioned from the right anymore
        tooltipcontainer.style.left = '0px';
      } else {
        tooltipcontainer.style.left = null;
        tooltipcontainer.style.right = `${viewportwidth - e.clientX}px`; // do the flipping
      }
    } else {
      // just position right of cursor
      tooltipcontainer.style.right = null;
      tooltipcontainer.style.left = `${e.clientX}px`;
    }
    // vertical
    if (e.clientY - offset - height < 0) {
      if (e.clientY + offset + height > viewportheight) {
        // would overflow bottom so clip to top
        tooltipcontainer.style.top = '0px';
      } else {
        tooltipcontainer.style.top = `${e.clientY + offset}px`; // display on bottom
      }
    } else {
      tooltipcontainer.style.top = `${e.clientY - offset - height}px`;
    }
  };

  const l = (e: MouseEvent) => {
    calculatePosition(e, tooltipcontainer);
  };

  const enter = (v: any) => (event: MouseEvent) => {
    setValues(v);
    calculatePosition(event, tooltipcontainer);
    // el.style.display = 'block';
    tooltipcontainer.style.opacity = '1';
    document.body.addEventListener('mousemove', l);
  };

  const leave = () => {
    // el.style.display = 'none';
    tooltipcontainer.style.opacity = '0';
    document.body.removeEventListener('mousemove', l);
  };

  const cleanup = () => {
    setCounter((c) => c - 1);
    if (counter() <= 0) {
      document.body.removeChild(tooltipcontainer);
      setCounter(0);
    }
  };

  const init = () => {
    document.body.appendChild(tooltipcontainer);
  };

  const store = {
    enter,
    leave,
    counter,
    setCounter,
    cleanup,
    init,
  };
  return (
    <TooltipContext.Provider value={store}>
      {props.children}
    </TooltipContext.Provider>
  );
};

export const tooltip = (el: Element, values: any) => {
  const ctx = useContext<TooltipContextValue>(TooltipContext);

  const myenter = ctx.enter(values);
  el.addEventListener('mouseenter', myenter);
  el.addEventListener('mouseleave', ctx.leave);
  ctx.setCounter((c: number) => c + 1);

  if (ctx.counter() === 1) {
    ctx.init();
  }

  onCleanup(() => {
    el.removeEventListener('mouseenter', myenter);
    el.removeEventListener('mouseleave', ctx.leave);
    ctx.cleanup();
  });
};
