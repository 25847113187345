import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  ListItem,
  SimpleGrid,
  Skeleton,
  Spinner,
  Text,
  Tooltip,
  UnorderedList,
} from '@hope-ui/solid';
import { Link } from 'solid-app-router';
import { createEffect, createSignal, For, Show, useContext } from 'solid-js';
import { BiRegularClipboard } from 'solid-icons/bi';
import { BsCheck2 } from 'solid-icons/bs';
import { IoEye, IoEyeOff, IoPerson } from 'solid-icons/io';
import { Button, Input } from '../../../components';
import DashboardLayout from '../../../layouts/DashboardLayout';
import { AuthContext } from '../../../userstore';

const Settings = () => {
  const [session, { regenerateApiKey, setUsername, togglePublic }] =
    useContext(AuthContext);
  const [usernameInput, setUsernameInput] = createSignal<string>();
  const [usernameSaving, setUsernameSaving] = createSignal(false);
  const [usernameSaved, setUsernameSaved] = createSignal(false);
  const [usernameTimeout, setUsernameTimeout] = createSignal<NodeJS.Timeout>();

  const [showApiKey, setShowApiKey] = createSignal(false);
  const [apiKeyCopied, setApiKeyCopied] = createSignal(false);
  const [apiKeyRegenerated, setApiKeyRegenerated] = createSignal(false);
  const [apiKeyRegenerating, setApiKeyRegenerating] = createSignal(false);

  const [publicInput, setPublicInput] = createSignal<boolean>();
  const [publicSaving, setPublicSaving] = createSignal(false);
  const [publicSaved, setPublicSaved] = createSignal(false);
  const [publicTimeout, setPublicTimeout] = createSignal<NodeJS.Timeout>();

  const saveUsername = async () => {
    if (usernameSaving() || usernameInput() === session.user.name) return;
    setUsernameSaving(true);

    try {
      await setUsername(usernameInput());
      setUsernameSaving(false);
      setUsernameSaved(true);
      if (usernameTimeout()) clearTimeout(usernameTimeout());

      const timeout = setTimeout(() => {
        setUsernameSaved(false);
      }, 4000);

      setUsernameTimeout(timeout);
    } catch (e) {
      //
    }
  };

  const regenApiKey = async () => {
    if (apiKeyRegenerated() || apiKeyRegenerating()) return;
    setApiKeyRegenerating(true);

    try {
      await regenerateApiKey();
      setApiKeyRegenerating(false);
      setApiKeyRegenerated(true);
      setTimeout(() => {
        setApiKeyRegenerated(false);
      }, 4000);
    } catch (e) {
      //
    }
  };

  const clipboardApiKey = () => {
    if (apiKeyCopied()) return;

    navigator.clipboard.writeText(session.user.apikey);
    setApiKeyCopied(true);

    setTimeout(() => {
      setApiKeyCopied(false);
    }, 1000);
  };

  const publicToggled = async () => {
    if (publicSaving()) return;

    setPublicSaving(true);

    try {
      await togglePublic();
      setPublicSaving(false);
      setPublicSaved(true);
      setPublicInput(session.user.public);
      if (publicTimeout()) clearTimeout(publicTimeout());

      const timeout = setTimeout(() => {
        setPublicSaved(false);
      }, 4000);

      setPublicTimeout(timeout);
    } catch (e) {
      //
    }
  };

  createEffect(() => {
    if (usernameInput() === undefined && session.user)
      setUsernameInput(session.user.name);

    if (publicInput() === undefined && session.user)
      setPublicInput(session.user.public);
  });

  return (
    <DashboardLayout title='Settings'>
      <SimpleGrid columns={{ '@initial': 1, '@lg': 2, '@2xl': 3 }} gap='$6'>
        <Box>
          <Heading>User Information</Heading>
          <Divider color='$container' my='$2' />
          <Box d='flex' flexDirection='column' gap='$4'>
            <FormControl>
              <FormLabel for='username'>Username</FormLabel>
              <InputGroup>
                <Input
                  id='username'
                  value={usernameInput() ?? ''}
                  onInput={(e: InputEvent) =>
                    setUsernameInput((e.target as HTMLInputElement).value)
                  }
                  autocomplete='off'
                  pr={'$14'}
                  onKeyDown={(e: KeyboardEvent) => {
                    if (e.key === 'Enter') saveUsername();
                  }}
                />
                <Show
                  when={
                    (session.user?.name &&
                      usernameInput() !== session.user.name) ||
                    usernameSaved()
                  }
                >
                  <InputRightElement
                    w={!usernameSaved() && !usernameSaving() ? '$14' : '$10'}
                  >
                    <Show when={!usernameSaved() && !usernameSaving()}>
                      <Button
                        bg='transparent'
                        color='$accentBlue'
                        p='$1'
                        pt='$0_5 !important'
                        onClick={() => saveUsername()}
                      >
                        Save
                      </Button>
                    </Show>
                    <Show when={usernameSaved() && !usernameSaving()}>
                      <Icon as={BsCheck2} color='$success9' />
                    </Show>
                    <Show when={usernameSaving()}>
                      <Spinner boxSize='$6' />
                    </Show>
                  </InputRightElement>
                </Show>
              </InputGroup>
            </FormControl>

            <FormControl>
              <FormLabel for='apiKey'>DRF Token</FormLabel>
              <InputGroup>
                <Input
                  id='apiKey'
                  value={session.user?.apikey}
                  placeholder='No token set, please generate one'
                  onInput={(e: InputEvent) => {
                    (e.target as HTMLInputElement).value = session.user.apikey;
                  }}
                  type={showApiKey() ? 'text' : 'password'}
                  autocomplete='off'
                  pr='$16'
                />
                <InputRightElement w='$16' gap='$1'>
                  <Tooltip
                    label={apiKeyCopied() ? 'Copied!' : 'Copy to clipboard'}
                    bg='$container'
                    color='white'
                    border='1px solid white'
                    placement='top'
                    opened={apiKeyCopied() || undefined}
                  >
                    <IconButton
                      icon={<BiRegularClipboard />}
                      aria-label='Copy DRF Token'
                      bg='transparent'
                      width='$6 !important'
                      height='$6'
                      disabled={!session.user?.apikey}
                      onClick={clipboardApiKey}
                    />
                  </Tooltip>
                  <Show when={showApiKey()}>
                    <IconButton
                      icon={<IoEyeOff />}
                      aria-label='Show DRF Token'
                      bg='transparent'
                      width='$6 !important'
                      height='$6'
                      onClick={() => setShowApiKey(!showApiKey())}
                    />
                  </Show>
                  <Show when={!showApiKey()}>
                    <IconButton
                      icon={<IoEye />}
                      aria-label='Show DRF Token'
                      bg='transparent'
                      width='$6 !important'
                      height='$6'
                      onClick={() => setShowApiKey(!showApiKey())}
                    />
                  </Show>
                </InputRightElement>
              </InputGroup>
              <Box d='flex' gap='$2' alignItems='center'>
                <Text
                  mt='$1'
                  color='$accentBlue'
                  cursor='pointer'
                  _hover={{
                    textDecoration: 'underline',
                  }}
                  fontSize='$sm'
                  onClick={() => regenApiKey()}
                >
                  {!session.user?.apikey
                    ? 'Generate Token'
                    : 'Regenerate Token'}
                </Text>
                <Show when={apiKeyRegenerating()}>
                  <Spinner color='white' boxSize='$3' mb='-$1_5' />
                </Show>
                <Show when={apiKeyRegenerated()}>
                  <Icon
                    as={BsCheck2}
                    color='$success9'
                    boxSize='$4'
                    mb='-$1_5'
                  />
                </Show>
              </Box>
            </FormControl>

            <Box>
              <Heading>Public Drop Research</Heading>
              <Box d='flex' gap='$2' alignItems='center' my='$1'>
                <Text as='label' for='public' fontSize='$sm'>
                  Contribute
                </Text>
                <Box
                  as='input'
                  id='public'
                  type='checkbox'
                  mb='-1px'
                  onInput={() => publicToggled()}
                  checked={publicInput()}
                />
                <Show when={publicSaved() && !publicSaving()}>
                  <Text fontSize='$xs' color='$accentBlue'>
                    Saved
                  </Text>
                </Show>
                <Show when={publicSaving()}>
                  <Spinner boxSize='$3' />
                </Show>
              </Box>
              <Box
                d='flex'
                flexDirection='column'
                gap='$1'
                fontSize='$sm'
                color='$textMuted'
              >
                <Text>
                  By default, we use the data you collect to help us generate
                  statistics and contribute to community data analysis efforts.
                  <br />
                  Here are some things to keep in mind:
                </Text>
                <UnorderedList>
                  <ListItem>
                    We will never share any raw data that is collected.
                  </ListItem>
                  <ListItem>All analysis is done anonymously.</ListItem>
                  <ListItem>
                    If you are using another addon that alters drop data, it is
                    a good idea to disable this setting.
                  </ListItem>
                  <ListItem>
                    Even if you forget to turn this off there is no need to
                    worry. Other players won't be able to know that your data is
                    part of public statistics.
                  </ListItem>
                </UnorderedList>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Heading>Guild Wars 2 Accounts</Heading>
          <Divider color='$container' my='$2' />
          <Box d='flex' flexDirection='column' gap='$4' mt='$5'>
            <Show
              when={session.user}
              fallback={<Skeleton h='$12' borderRadius='5px' />}
            >
              <For each={session.user.gw2_accounts}>
                {(account) => (
                  <Box d='flex' gap='$2' alignItems='center'>
                    <Icon as={IoPerson} mb='-1px' color='$accentBlue' />
                    <Text>
                      {account.display_name.split('.')[0]}
                      <Text as='span' color='$textMuted'>
                        .{account.display_name.split('.')[1]}
                      </Text>
                    </Text>
                  </Box>
                )}
              </For>
            </Show>
            <Link
              href='https://api.drf.rs/auth/login?prompt=true'
              target='_blank'
            >
              <Button w='$full'>Edit Accounts</Button>
            </Link>
          </Box>
        </Box>
      </SimpleGrid>
    </DashboardLayout>
  );
};

export default Settings;
