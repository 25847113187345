import { Text } from '@hope-ui/solid';
import { IoSettingsSharp } from 'solid-icons/io';
import { Show, useContext } from 'solid-js';
import { Button, Flex, Link, WaitingData } from '..';
import { AuthContext } from '../../userstore';

export const NoUserData = () => {
  const [state] = useContext(AuthContext);

  return (
    <Flex flexDirection='column' gap='$8'>
      <Show when={state.user?.gw2_accounts.length === 0}>
        <Flex
          d='flex'
          flexDirection={{ '@initial': 'column', '@xl': 'row' }}
          alignItems={{ '@initial': 'start', '@xl': 'center' }}
          gap='$4'
          bg='$warning1'
          p='$3'
          borderRadius='5px'
          outline='1px solid $warning8'
        >
          <Text>
            There was no account found! Head over to the settings page and make
            sure that you see your Guild Wars 2 account listed.
          </Text>
          <Link href='/dashboard/user/settings' noExternalIcon flexShrink={0}>
            <Button leftIcon={<IoSettingsSharp />} p='$1_5' fontSize='$sm'>
              Settings
            </Button>
          </Link>
        </Flex>
      </Show>
      <WaitingData />
    </Flex>
  );
};

export default NoUserData;
