import { Rarity } from 'gw2api-client';
import { Filter, SessionDropData } from '../../../context/DRFContext';

export type ItemRarityFilterOptions = {
  rarities: Rarity[];
  exclusionFilter?: boolean;
};

export const createItemRarityFilter = (
  options: ItemRarityFilterOptions
): Filter<ItemRarityFilterOptions> => {
  const processor = (data: SessionDropData) => {
    const newData: SessionDropData = { ...data, currencies: [] };

    newData.items = data.items.filter((item) =>
      options.exclusionFilter
        ? !options.rarities.includes(item.api.rarity)
        : options.rarities.includes(item.api.rarity)
    );

    return newData;
  };

  return {
    type: 'rarity',
    options,
    processor,
  };
};
