import { Component } from 'solid-js';
import Chart from './Chart';

export type TimelineGraphProps = {
  backlog?: number;
};

export const TimelineGraph: Component<TimelineGraphProps> = (props) => {
  const margins = {
    top: 10,
    right: 60,
    bottom: 30,
    left: 0,
  };

  return <Chart margins={margins} backlog={props.backlog} />;
};
