import {
  createContext,
  createEffect,
  createMemo,
  createSignal,
  useContext,
} from 'solid-js';
import { createStore } from 'solid-js/store';
import { createContextProvider } from '@solid-primitives/context';
import { extent, scaleLinear, scaleTime } from 'd3';
import { DataContext } from '../../context/DataContext';

export const ChartContext = createContext();
export type TimelineGraphData = {
  value: number;
  timestamp: number;
};

const DEFAULT_MARGIN = {
  top: 20,
  right: 40,
  bottom: 30,
  left: 30,
};

function findIndexRev<T>(array: T[], pred: (item: T) => boolean): number {
  for (let i = array.length - 1; i >= 0; --i) {
    if (pred(array[i])) {
      return i;
    }
  }
  return -1;
}

export const [ChartProvider, useChart] = createContextProvider(() => {
  const [dimensions, setDimensions] = createStore({
    width: 470,
    height: 230,
    margins: DEFAULT_MARGIN,
  });
  const [backlog, setBacklog] = createSignal(60);
  const { state } = useContext(DataContext);
  const [chartdata, setChartData] = createStore<{
    data: TimelineGraphData[];
  }>({
    data: [],
  });

  createEffect(() => {
    setChartData('data', (data: TimelineGraphData[]) => {
      const i = findIndexRev(
        data,
        (e) =>
          e.timestamp.valueOf() <
          state.sessionStats.lastUpdate - backlog() * 60 * 1000
      );
      if (i >= 0 && i < data.length - 2) {
        data = data.slice(i + 1);
      }
      data.push({
        value: state.sessionStats.profit,
        timestamp: state.sessionStats.lastUpdate,
      });
      data.sort((a, b) => a.timestamp.valueOf() - b.timestamp.valueOf());
      return [...data];
    });
  });

  const ceilTo = (value: number, multiple: number): number => {
    return Math.ceil(value / multiple) * multiple;
  };
  const floorTo = (value: number, multiple: number): number => {
    return Math.floor(value / multiple) * multiple;
  };

  const domains = createMemo(() => {
    const d = chartdata.data;
    if (d.length === 0) {
      return {
        xDomain: [new Date(0), new Date()] as const,
        yDomain: [0, 0] as const,
      };
    }
    const xDomain = [
      new Date(d[0].timestamp),
      new Date(d[d.length - 1].timestamp),
    ] as const;
    const tmp = extent(d, (item) => item.value) as [number, number];
    let yDomain = [floorTo(tmp[0], 10000), ceilTo(tmp[1], 10000)] as const;
    if (yDomain[1] - yDomain[0] > 100000) {
      yDomain = [
        floorTo(yDomain[0], 100000),
        ceilTo(yDomain[1], 100000),
      ] as const;
    }

    return {
      xDomain,
      yDomain,
    };
  });

  const ranges = () => ({
    xRange: [
      dimensions.margins.left,
      dimensions.width - dimensions.margins.right,
    ] as const,
    yRange: [
      dimensions.height - dimensions.margins.bottom,
      dimensions.margins.top,
    ] as const,
  });

  const scales = {
    xScale: () => scaleTime().range(ranges().xRange).domain(domains().xDomain),
    yScale: () =>
      scaleLinear().range(ranges().yRange).domain(domains().yDomain),
  };

  const innerHeight = createMemo(
    () => dimensions.height - dimensions.margins.top - dimensions.margins.bottom
  );
  const innerWidth = createMemo(
    () => dimensions.width - dimensions.margins.left - dimensions.margins.right
  );

  return {
    data: () => chartdata.data,
    dimensions: () => dimensions,
    setBacklog,
    setDimensions,
    innerHeight,
    innerWidth,
    scales,
  };
});
