import { For, Match, Show, Switch, useContext } from 'solid-js';
import type { Component } from 'solid-js';
import {
  Box,
  Button,
  createDisclosure,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Grid,
  Heading,
  Spinner,
  Text,
  useTheme,
} from '@hope-ui/solid';
import { NavLink, useLocation } from 'solid-app-router';
import { createBreakpoints } from '@solid-primitives/media';

import { BiRegularExport, BiRegularReset } from 'solid-icons/bi';

import DashboardLayout from '../../layouts/DashboardLayout';
import FilterGroup from '../../components/filter/FilterGroup';
import {
  DevGate,
  Flex,
  HighlightConfig,
  SessionSummary,
} from '../../components';
import GW2Coin from '../../components/gw2/GW2Coin';
import GW2Currency from '../../components/gw2/GW2Currency';
import { DataContext } from '../../context/DataContext';
import TimelineView from '../../components/livetracker/TimelineView';
import { TimelineGraph } from '../../components/chart/TimelineGraph';

const TabLink: Component<{ href: string; selected?: boolean }> = (props) => {
  const location = useLocation();

  return (
    <NavLink href={props.href} style={{ 'text-decoration': 'none' }}>
      <Box
        pb='$1'
        fontSize='$xl'
        borderBottom={
          location.pathname === props.href ? '2px solid $accentBlue' : 'none'
        }
      >
        {props.children}
      </Box>
    </NavLink>
  );
};

const DebugStats = () => {
  const { state } = useContext(DataContext);

  return (
    <Box d='flex' flexDirection={{ '@initial': 'column', '@lg': 'row' }}>
      <Box d='flex' alignItems='center' pos='relative' gap='1ch'>
        <DevGate>
          <Text color='$whiteAlpha10'>
            Events Loaded: {state.events.length}
          </Text>
        </DevGate>
        <Text color='$whiteAlpha10'>Total Events: {state.totalEvents}</Text>
      </Box>
    </Box>
  );
};

const NoDataYet = (props) => (
  <Flex gap='$2' color='$whiteAlpha10' alignItems='center'>
    <Spinner size='xs' speed='1.5s' />
    {props.children ?? 'No data yet'}
  </Flex>
);

const StatsEntry = (props) => {
  const { state } = useContext(DataContext);

  return (
    <Box>
      <Text fontSize='$sm'>{props.children}</Text>
      <Show when={state.events.length > 0} fallback={<NoDataYet />}>
        <GW2Coin fontSize='$xl' value={props.value} />
      </Show>
    </Box>
  );
};

const SessionStats = () => {
  const { state, desync } = useContext(DataContext);

  return (
    <>
      <Grid
        p='$4'
        borderRadius='5px'
        border='1px solid $container'
        gap='$4'
        gridTemplateColumns='repeat(auto-fit, minmax(max(220px, 50% - $4), 1fr))'
      >
        <StatsEntry value={state.sessionStats.profit}>
          <Flex alignItems='center' gap='$2'>
            Approx. Profits
            <Show when={desync()}>
              <Spinner size='xs' speed='1.5s' />
            </Show>
          </Flex>
        </StatsEntry>
        <StatsEntry value={state.sessionStats.goldPerHour}>
          Approx. Gold Per Hour
        </StatsEntry>
        <StatsEntry value={state.sessionStats.tpProfit}>
          Approx. Trading Profits
        </StatsEntry>
        <StatsEntry value={state.sessionStats.tpPerHour}>
          Approx. Trading Profits Per Hour
        </StatsEntry>
        <Box gridColumn='1 / -1'>
          <Text fontSize='$sm'>Approx. Profit Chart</Text>
          <Show when={state.events.length > 1} fallback={<NoDataYet />}>
            <TimelineGraph />
          </Show>
        </Box>
      </Grid>

      <Flex
        p='$4'
        flexDirection='column'
        borderRadius='5px'
        border='1px solid $container'
        gap='$2'
      >
        <Box>
          <Text fontSize='$sm'>Currency Summary</Text>
          <Show
            when={state.summary.currencies.length > 0}
            fallback={<NoDataYet>No currencies have dropped yet</NoDataYet>}
          >
            <For each={state.summary.currencies}>
              {(currency) => (
                <GW2Currency id={currency.id} amount={currency.amount} />
              )}
            </For>
          </Show>
        </Box>
      </Flex>
    </>
  );
};

const ActionPanel = () => {
  const { state, exportSummary, exportTimeline, resetWorkers } =
    useContext(DataContext);
  const location = useLocation();

  return (
    <>
      <DevGate>
        <Button
          leftIcon={<BiRegularReset />}
          onClick={() => {
            resetWorkers();
          }}
        >
          Reset Workers
        </Button>
      </DevGate>

      <Switch>
        <Match when={location.pathname.endsWith('/livetracker')}>
          <Button
            leftIcon={<BiRegularExport />}
            disabled={state.events.length === 0}
            onClick={() => {
              exportTimeline();
            }}
          >
            Export Timeline
          </Button>
        </Match>

        <Match when={location.pathname.endsWith('/livetracker/summary')}>
          <Button
            leftIcon={<BiRegularExport />}
            disabled={state.events.length === 0}
            onClick={() => {
              exportSummary();
            }}
          >
            Export Summary
          </Button>
        </Match>
      </Switch>

      <DevGate
        fallback={
          <Box
            d='flex'
            p='$8'
            borderRadius='5px'
            border='1px solid $containerOutline'
            justifyContent='center'
          >
            Filter options coming soon!
          </Box>
        }
      >
        <Flex flexDirection='column' gap='$8'>
          <FilterGroup />
        </Flex>

        <HighlightConfig />
      </DevGate>
    </>
  );
};

const LiveTracker = () => {
  const { state } = useContext(DataContext);
  const location = useLocation();

  const {
    isOpen: sessionStatsIsOpen,
    onClose: sessionStatsClose,
    onOpen: sessionStatsOpen,
  } = createDisclosure();

  const {
    isOpen: filtersIsOpen,
    onClose: filtersClose,
    onOpen: filtersOpen,
  } = createDisclosure();

  const theme = useTheme();
  const matches = createBreakpoints({
    lg: `${theme().sizes.containerLg.value}`,
    xl: `${theme().sizes.containerXl.value}`,
  });

  return (
    <DashboardLayout title='Live Tracker'>
      <Box
        d='flex'
        mb='$4'
        boxShadow='0 -2px 0px rgba(255,255,255,0.2) inset'
        gap='$6'
        alignItems='center'
      >
        <TabLink href='/dashboard/livetracker'>Timeline View</TabLink>
        <TabLink href='/dashboard/livetracker/summary'>Session Summary</TabLink>
        <Show when={matches.xl}>
          <Box flex={1} />
          <DebugStats />
        </Show>
      </Box>

      <Show when={!matches.xl}>
        <Box d='flex' gap='$4' justifyContent='space-between' mb='$4'>
          <Button
            flex={1}
            bg='transparent'
            border='1px solid $containerOutline'
            onClick={sessionStatsOpen}
          >
            Session Stats
          </Button>
          <Button
            flex={1}
            bg='transparent'
            border='1px solid $containerOutline'
            onClick={filtersOpen}
          >
            Actions
          </Button>
        </Box>

        <Show when={state.filters.length > 0}>
          <Box
            mb='$4'
            mt='-$2'
            px='$2'
            py='$1_5'
            bg='$container'
            borderRadius='4px'
          >
            <Text>{state.filters.length} active filter</Text>
          </Box>
        </Show>

        <Drawer
          opened={sessionStatsIsOpen()}
          placement='left'
          onClose={sessionStatsClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <Box d='flex' flexDirection='column' gap='$4' p='$4'>
              <Heading size='xl'>Session Stats</Heading>

              <Box d='flex' flexDirection='column' gap='$2'>
                <SessionStats />
              </Box>
            </Box>
          </DrawerContent>
        </Drawer>

        <Drawer
          opened={filtersIsOpen()}
          placement='right'
          onClose={filtersClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <Box d='flex' flexDirection='column' gap='$4' p='$4'>
              <Heading size='xl'>Actions</Heading>

              <Box d='flex' flexDirection='column' gap='$2'>
                <ActionPanel />
              </Box>
            </Box>
          </DrawerContent>
        </Drawer>
      </Show>

      {/* <Show when={!matches.xl}>
        <Box>
          <DebugStats />
        </Box>
      </Show> */}

      <Flex gap='$10'>
        <Show when={matches.xl}>
          <Flex flex='1 0' flexDirection='column' gap='$2'>
            <SessionStats />
          </Flex>
        </Show>

        <Flex flexDirection='column' flex='2 0'>
          <Switch>
            <Match when={location.pathname.endsWith('/livetracker')}>
              <TimelineView />
            </Match>

            <Match when={location.pathname.endsWith('/livetracker/summary')}>
              <SessionSummary summary={state.summary} />
            </Match>
          </Switch>
        </Flex>

        <Show when={matches.xl}>
          <Flex flex='1 0' flexDirection='column' gap='$2' maxWidth={240}>
            <ActionPanel />
          </Flex>
        </Show>
      </Flex>
    </DashboardLayout>
  );
};

export default LiveTracker;
