import { line } from 'd3';
import { useChart } from './ChartContext';

export default function LineSeries() {
  const { scales, data } = useChart()!;
  const d = () => {
    return line<ReturnType<typeof data>[number]>()
      .x((l) => scales.xScale()(new Date(l.timestamp)))
      .y((l) => scales.yScale()(l.value));
  };

  return (
    <path
      d={d()(data())}
      fill='none'
      stroke-linecap='round'
      stroke-width={1}
      stroke='lightblue'
    />
  );
}
