import { createContext, Component, Context } from 'solid-js';
import { createStore, DeepReadonly } from 'solid-js/store';
import gw2api from '../../lib/gw2api';

export const GW2ApiContext: Context<StoreType> = createContext();

type State = { gw2api: any };
type StoreType = [DeepReadonly<State>];

export const GW2ApiProvider: Component = (props) => {
  const [state] = createStore<State>({ gw2api });

  const store: StoreType = [state];

  return (
    <GW2ApiContext.Provider value={store}>
      {props.children}
    </GW2ApiContext.Provider>
  );
};
