import type { HeadingStyleConfig } from '@hope-ui/solid';

export const Heading: HeadingStyleConfig = {
  baseStyle: {
    fontFamily: '$sans',
    fontWeight: '$normal',
  },
};

export default { Heading };
