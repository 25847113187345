import DropBuffer from './sync/dropbuffer';
import { binaryInsertion } from '../util';
import DropFactory, { CurrencyData, ItemData } from './drop';
import Gw2Worker from '../gw2worker';

export default class Summary {
  private itemBuffer: DropBuffer;
  private lastIndex: number;
  private rawProfits: RawProfits;
  private factory: DropFactory;

  summary: SessionSummary;
  stats: SessionStats;

  constructor(buffer: DropBuffer, worker: Gw2Worker) {
    this.itemBuffer = buffer;
    this.factory = new DropFactory(buffer, [], worker);
    this.reset();
  }

  async add(index: number): Promise<SessionStats | null> {
    if (index > this.lastIndex) {
      this.lastIndex = index;
    }

    const item = Atomics.load(this.itemBuffer.dropItem, index);
    if (Number(item) !== 0) {
      await this.addItem(index);
    } else {
      const curr = Atomics.load(this.itemBuffer.dropCurr, index);
      if (Number(curr) !== 0) {
        await this.addCurr(index);
      }
    }

    if (index >= this.lastIndex) {
      return this.stats;
    }
    return null;
  }

  async addItem(idx: number) {
    const adata = await this.factory.buildItemData(idx);
    const data = await DropFactory.awaitItemData(adata);

    const i = this.summary.items.findIndex((x) => x.id === data.id);
    if (i === -1) {
      this.addToProfit(data, 0);
      this.summary.items.push(data);
    } else {
      this.addToProfit(data, this.summary.items[i].amount);
      data.amount += this.summary.items[i].amount;
      if (!data.tp) {
        data.tp = this.summary.items[i].tp;
      }
      if (data.amount === 0) {
        this.summary.items.splice(i, 1);
        return;
      }

      this.summary.items[i] = data;
    }
  }

  async addCurr(idx: number) {
    const data = await this.factory.buildCurrencyData(idx);
    this.addCurrToProfit(data);

    const i = this.summary.currencies.findIndex((x) => x.id === data.id);
    if (i === -1) {
      binaryInsertion(
        this.summary.currencies,
        data,
        (a, b) => a.order - b.order
      );
    } else {
      this.summary.currencies[i].amount += data.amount;
    }
  }

  sort() {
    this.summary.items.sort((a, b) => b.id - a.id);
    this.summary.items.sort((a, b) => getItemAbsValue(b) - getItemAbsValue(a));
  }

  private async addToProfit(data: ItemData, existingAmount: number) {
    const { tp, api } = data;
    if (tp) {
      if (existingAmount > 0 || (existingAmount === 0 && data.amount > 0)) {
        this.rawProfits.gainedSell +=
          (tp.sell ? tp.sell : tp.buy) * data.amount;
      } else if (
        existingAmount < 0 ||
        (existingAmount === 0 && data.amount < 0)
      ) {
        this.rawProfits.lostBuy += tp.buy * data.amount;
        this.rawProfits.lostSell += (tp.sell ? tp.sell : tp.buy) * data.amount;
      }
    } else if (api && !api.flags.includes('NoSell')) {
      this.rawProfits.vendor += (api.vendor_value ?? 0) * data.amount;
    }

    this.recalculateStats();
  }

  private addCurrToProfit(data: CurrencyData) {
    if (data.id === 1) {
      this.rawProfits.currencies += data.amount;

      this.recalculateStats();
    }
  }

  private recalculateStats() {
    const r = this.rawProfits;
    const s = this.stats;
    s.profit = r.gainedSell + r.lostSell + r.vendor + r.currencies;
    s.tpProfit = r.gainedSell * 0.85 + r.lostBuy + r.vendor + r.currencies;

    const start = Number(Atomics.load(this.itemBuffer.dropTime, 0));
    const end = Number(Atomics.load(this.itemBuffer.dropTime, this.lastIndex));
    const duration = (end - start) / (60 * 60 * 1000);

    s.goldPerHour = duration ? s.profit / duration : s.profit;
    s.tpPerHour = duration ? s.tpProfit / duration : s.tpProfit;
    s.lastUpdate = end;
  }

  reset() {
    this.summary = { items: [], currencies: [] };
    this.stats = {
      profit: 0,
      goldPerHour: 0,
      tpProfit: 0,
      tpPerHour: 0,
      lastUpdate: 0,
    };
    this.rawProfits = {
      gainedSell: 0,
      lostSell: 0,
      lostBuy: 0,
      currencies: 0,
      vendor: 0,
    };
    this.lastIndex = 0;
  }
}

export function getItemAbsValue(item: ItemData): number {
  const amount = Math.abs(item.amount);
  return getItemValuation(item) * amount;
}

export function getItemValuation(item: ItemData): number {
  const nosell = item.api?.flags.includes('NoSell');
  const tp = item.tp?.sell ? item.tp?.sell : item.tp?.buy ?? 0;
  const vendor = nosell ? 0 : item.api?.vendor_value ?? 0;
  return Math.max(tp, vendor);
}

export type SessionSummary = {
  items: ItemData[];
  currencies: CurrencyData[];
};

export type SessionStats = {
  profit: number;
  goldPerHour: number;
  tpProfit: number;
  tpPerHour: number;
  lastUpdate: number;
};

type RawProfits = {
  gainedSell: number;
  lostSell: number;
  lostBuy: number;
  vendor: number;
  currencies: number;
};
