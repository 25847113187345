import { ComponentStyleConfigs, StitchesThemeConfig } from '@hope-ui/solid';
import { globalColors } from './colors';
import * as components from './components';

const componentStyles: ComponentStyleConfigs = {
  ...components,
};

const fonts = {
  sans: 'Karla, sans-serif',
  menomonia: 'Menomonia, Lato, sans-serif',
};

const lightTheme: StitchesThemeConfig = {
  fonts,
  colors: {
    ...globalColors,
    background:
      'linear-gradient(198.33deg, #253362 -14.57%, #0C1226 20.71%, #080808 90.11%)',
    sidebarBackground: '$blackAlpha2',
  },
};

const darkTheme: StitchesThemeConfig = {
  fonts,
  colors: {
    ...globalColors,
    background:
      'linear-gradient(198.33deg, #253362 -14.57%, #0C1226 20.71%, #080808 90.11%)',
    sidebarBackground: 'rgba(0,0,0,0.2)',
    sidebarBorder: 'rgba(255,255,255,0.1)',
    sidebarMenuTitle: 'rgba(255,255,255,0.6)',
    sidebarButtonHover: 'rgba(255,255,255,0.05)',
    accentBlue: '#4069FF',
    accentDarker: '#2a50d2',
    container: 'rgba(255,255,255,0.05)',
    containerOutline: 'rgba(255,255,255,0.2)',
    overlayContainer: 'rgba(0,0,0,0.5)',
    focusOutline: 'rgba(255, 255, 255, 0.8)',
    textMuted: '#b4b4b4',
  },
};

export { componentStyles, lightTheme, darkTheme };
