import { Icon, Text } from '@hope-ui/solid';
import { IconTypes } from 'solid-icons';
import {
  Component,
  createEffect,
  createSignal,
  For,
  onCleanup,
  onMount,
  splitProps,
} from 'solid-js';

import { AiOutlineExperiment, AiOutlineRight } from 'solid-icons/ai';

import { Box, Flex } from '../global';

const ListItem: Component<{
  icon?: IconTypes;
  name: string;
  selected?: boolean;
}> = (props) => {
  const [local, rest] = splitProps(props, ['icon', 'name', 'selected']);

  return (
    <Flex
      bg={props.selected ? '$accentBlue' : '$whiteAlpha3'}
      alignItems='center'
      px='$2_5'
      py='$0_5'
      gap='$2'
      fontSize='$sm'
      cursor='pointer'
      _hover={{
        bg: local.selected ? undefined : '$whiteAlpha5',
      }}
      _active={{
        bg: '$accentDarker',
      }}
      transition='all ease-in-out 0.2s'
      role='group'
      {...rest}
    >
      <Icon
        as={local.icon ?? AiOutlineExperiment}
        color={local.selected ? 'white' : '$accentBlue'}
        transition='all ease-in-out 0.2s'
        _groupActive={{
          color: 'white',
        }}
      />
      <Text userSelect='none'>{local.name}</Text>
      <Box flex='1' />
      <Icon
        as={AiOutlineRight}
        opacity={local.selected ? 1 : 0}
        transition='all ease-in-out 0.2s'
        _groupHover={{
          opacity: 1,
        }}
      />
    </Flex>
  );
};

export const FilterList: Component<{
  index?: number;
  search?: string;
  filterList: { icon?: IconTypes; name: string; selected?: boolean }[];
  onFilterSelected?: (filter: any) => void;
}> = (props) => {
  const [filteredFilterList, setFilteredFilterList] = createSignal([]);
  const [index, setIndex] = createSignal(null);

  createEffect(() => {
    setFilteredFilterList(
      props.search
        ? props.filterList.filter((e) =>
            e.name.toLowerCase().includes(props.search.toLowerCase())
          )
        : props.filterList
    );
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (
      e.key === 'Enter' &&
      props.onFilterSelected &&
      index() < filteredFilterList().length
    ) {
      props.onFilterSelected(filteredFilterList()[index()]);
    }

    if (e.key === 'ArrowUp' && index() > 0) {
      e.preventDefault();
      setIndex((index() ?? 1) - 1);
    }

    if (e.key === 'ArrowDown' && index() < filteredFilterList().length - 1) {
      e.preventDefault();
      setIndex((index() ?? -1) + 1);
    }
  };

  onMount(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onCleanup(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

  return (
    <Flex flexDirection='column' gap='1px'>
      <For each={filteredFilterList()}>
        {(filter, i) => (
          <ListItem
            {...filter}
            selected={index() === i()}
            onclick={() => props.onFilterSelected(filteredFilterList()[i()])}
          />
        )}
      </For>
    </Flex>
  );
};

export default FilterList;
