import { HopeProps, Text } from '@hope-ui/solid';
import type { Component, ComponentProps } from 'solid-js';

import { FaSolidCheck as CheckIcon } from 'solid-icons/fa';

import Box from './Box';
import { Button } from './Button';
import Link from './Link';

export type CookieBarProps = {
  onAcknowledged: () => void;
} & HopeProps &
  ComponentProps<'div'>;

export const CookieBar: Component<CookieBarProps> = (props) => {
  return (
    <Box
      pos='fixed'
      display='flex'
      bottom={{ '@initial': 0, '@sm': '$6' }}
      right={{ '@initial': 0, '@sm': '$6' }}
      left={{ '@initial': 0, '@sm': 'auto' }}
      p='$4'
      gap='$4'
      background='$neutral1'
      borderColor='$accentBlue'
      borderTopWidth={1}
      borderWidth={{ '@sm': 1 }}
      flexDirection='column'
      borderRadius={{ '@sm': 5 }}
      maxWidth={{ '@sm': 380 }}
      zIndex={1}
      {...props}
    >
      <Text>
        This website uses cookies to enable essential functionality.{' '}
        <Link
          href='https://www.cloudflare.com/learning/privacy/what-are-cookies/'
          fontWeight='$bold'
          _hover={{
            color: '$textMuted',
          }}
        >
          Learn More
        </Link>
      </Text>
      <Button
        p='$2'
        background='$warning10'
        color='black'
        _hover={{
          color: 'white',
          outlineColor: '$warning10 !important',
        }}
        onClick={() => props.onAcknowledged()}
        leftIcon={<CheckIcon />}
      >
        Acknowledge
      </Button>
    </Box>
  );
};

export default CookieBar;
