import { For, createMemo } from 'solid-js';
import { useChart } from './ChartContext';

export type AxisBottomProps = {
  pixelsPerTick?: number;
  numberOfTicks?: number;
};

export default function AxisBottom(props: AxisBottomProps) {
  const { scales, dimensions, innerHeight } = useChart()!;

  const range = () => scales.xScale().range();

  const ticks = createMemo(() => {
    const scale = scales.xScale();
    const r = range();
    const width = r[1] - r[0];
    const pixelsPerTick = props.pixelsPerTick ?? 100;
    const numberOfTicks =
      props.numberOfTicks ?? Math.max(1, Math.floor(width / pixelsPerTick));

    const format =
      'tickFormat' in scale
        ? scale.tickFormat()
        : (v?: { toString(): string }) => v?.toString();

    return scale.ticks(numberOfTicks).map((v) => ({
      v: format(v),
      xOffset: scale(v),
    }));
  });

  const d = () =>
    ['M', range()[0], 6, 'v', -6, 'H', range()[1], 'v', 6].join(' ');

  return (
    <g transform={`translate(0, ${dimensions().margins.top + innerHeight()})`}>
      <path d={d()} fill='none' stroke='currentColor' />
      <For each={ticks()}>
        {({ v, xOffset }) => (
          <g transform={`translate(${xOffset}, 0)`}>
            <line y2={6} stroke='currentColor' />
            <text
              fill='currentColor'
              style={{
                'font-size': '12px',
                'text-anchor': 'middle',
                transform: 'translateY(20px)',
              }}
            >
              {v}
            </text>
          </g>
        )}
      </For>
    </g>
  );
}
