// every offset is 4 byte
export const BYTE_STEP = 4;
export const MAX_WORKER = 10;
/// write head of the ingest worker
export const WRITE_HEAD = 0;
export const FILTER_HEADS = WRITE_HEAD + 1;
export const FILTER_INDICES = FILTER_HEADS + MAX_WORKER;
export const FILTERJOIN_HEAD = FILTER_INDICES + MAX_WORKER;
export const FILTERJOIN_INDEX = FILTERJOIN_HEAD + 1;
export const TIMELINE_HEAD = FILTERJOIN_INDEX + 1;
export const SUMMARY_HEAD = TIMELINE_HEAD + 1;
export const SYNC_BUFFER_LENGTH = SUMMARY_HEAD + 1;

export const WRITE_HEAD_OFFSET = WRITE_HEAD * BYTE_STEP;
export const FILTER_HEADS_OFFSET = FILTER_HEADS * BYTE_STEP;
export const FILTER_INDICES_OFFSET = FILTER_INDICES * BYTE_STEP;
export const FILTERJOIN_HEAD_OFFSET = FILTERJOIN_HEAD * BYTE_STEP;
export const FILTERJOIN_INDEX_OFFSET = FILTERJOIN_INDEX * BYTE_STEP;
export const TIMELINE_HEAD_OFFSET = TIMELINE_HEAD * BYTE_STEP;
export const SUMMARY_HEAD_OFFSET = SUMMARY_HEAD * BYTE_STEP;
export const SYNC_BUFFER_SIZE = SYNC_BUFFER_LENGTH * BYTE_STEP;
