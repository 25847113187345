import { Spinner, Text } from '@hope-ui/solid';

import {
  Component,
  createEffect,
  createSignal,
  For,
  onCleanup,
  Show,
  useContext,
} from 'solid-js';
// import { TransitionGroup } from 'solid-transition-group';
// import { Key } from '@solid-primitives/keyed';
import { Box, Flex } from '../global';
import { SessionEvent } from './SessionEvent';
import NoUserData from './NoUserData';
import { DataContext, MIN_SLICE_AMOUNT } from '../../context/DataContext';
import { SessionDropData } from '../../lib/drf/drop';

const TimelineView: Component = () => {
  const ctx = useContext(DataContext);
  const [observer, setObserver] = createSignal<IntersectionObserver>();
  const [shrinkElement, setShrinkElement] = createSignal<HTMLDivElement>();
  const [loadMoreElement, setLoadMoreElement] = createSignal<HTMLDivElement>();

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;
      if (entry.target.id === 'events-shrink') {
        ctx.setCurrentSlice(MIN_SLICE_AMOUNT);
      }

      if (entry.target.id === 'events-grow') {
        ctx.setCurrentSlice(ctx.currentSlice() + MIN_SLICE_AMOUNT);
      }
    });
  };

  createEffect(() => {
    if (!observer()) {
      const newObserver = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '1px',
        threshold: 0.1,
      });

      setObserver(newObserver);

      return;
    }

    if (shrinkElement()) {
      observer().observe(shrinkElement());
    }

    if (loadMoreElement()) {
      observer().observe(loadMoreElement());
    }

    onCleanup(() => observer().disconnect());
  });

  return (
    <Flex flexDirection='column' gap='$4' overflow='hidden' paddingBottom='$4'>
      <Show when={ctx.state.events.length} fallback={<NoUserData />}>
        <Box id='events-shrink' my='0' ref={(e) => setShrinkElement(e)} />

        {/* <TransitionGroup name='item-view-shuffle'>
          <Key each={ctx.state.events} by={(x) => x.timestamp}> */}
        <For each={ctx.state.events}>
          {(event) => <SessionEvent event={event as SessionDropData} />}
        </For>
        {/* </Key>
        </TransitionGroup> */}

        <Show when={ctx.state.totalEvents > ctx.currentSlice()}>
          <Flex
            id='events-grow'
            ref={(e) => setLoadMoreElement(e)}
            justifyContent='center'
            my='$8'
          >
            <Text display='flex' alignContent='center' fontSize='$lg'>
              <Spinner speed='2s' mr='$4' />
              Loading more items...
            </Text>
          </Flex>
        </Show>
      </Show>
    </Flex>
  );
};

export default TimelineView;
