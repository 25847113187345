import { createEffect, createMemo, createSignal, Show } from 'solid-js';
import { bisectCenter } from 'd3';
import { Box, Flex, Text } from '@hope-ui/solid';
import AxisBottom from './AxisBottom';
import AxisRight from './AxisRight';
import { useChart } from './ChartContext';
import LineSeries from './LineSeries';
import GW2Coin from '../gw2/GW2Coin';
import EventEmitter from './EventEmitter';
import { tooltip } from '../../directives/tooltip';

import TooltipBackground from '../../assets/tooltip.png';
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
tooltip; // avoid early treeshaking

const ChartTooltip = (props: {
  data?: { value: number; timestamp: number };
}) => (
  <Show when={props.data}>
    <Box
      p='5px'
      maxW='300px'
      border='2px solid rgba(0, 0, 0, 0.8)'
      background={`url(${TooltipBackground})`}
      style={{
        'text-shadow': '1px 1px 0 rgba(0, 0, 0, 0.8)',
      }}
    >
      <Flex direction={'column'} gap='$3'>
        <Box>
          <Text fontFamily='$menomonia' fontSize='$sm' lineHeight='1.1rem'>
            {new Date(props.data.timestamp).toLocaleString()}
          </Text>
        </Box>
        <Box>
          <Text fontFamily='$menomonia' fontSize='$sm' lineHeight='1.1rem'>
            Approx. Profits
          </Text>
          <GW2Coin value={props.data.value} />
        </Box>
      </Flex>
    </Box>
  </Show>
);

const tickLabel = (domainSize: number, marginRight: number) => (v: number) => {
  const element = (
    <foreignObject
      font-size='12px'
      width={marginRight - 10}
      height={21}
      overflow='visible'
      style={{
        transform: 'translate(10px, -6px)',
      }}
    >
      <GW2Coin xmlns='http://www.w3.org/1999/xhtml' value={v} />
    </foreignObject>
  );
  // if more than 10g only show in 10 increments
  if (domainSize > 100000 && v % 100000 === 0) {
    return element;
  }
  // otherwise show in 1g increments
  if (v % 10000 === 0) {
    return element;
  }
  // unless value is between -1g and 1g show silver values
  // if domain is from 0 to 2g for example this will show 50s but not 1g50s
  if (v < 10000 && v > -10000) {
    return element;
  }
  // always show ticks but no labels for in between values like 1.5g
  return <></>;
};

export default function Chart(props) {
  const { data, dimensions, setDimensions, setBacklog, scales, innerHeight } =
    useChart()!;

  createEffect(() => {
    if (props.margins) {
      setDimensions('margins', props.margins);
    }
    if (props.backlog) {
      setBacklog(props.backlog);
    }
  });

  const [showTooltip, setShowTooltip] = createSignal(false);
  const [tooltipData, setTooltipData] = createSignal<{
    offsetX: number;
    offsetY: number;
    i: number;
    data: ReturnType<typeof data>[number];
  }>();

  const format = createMemo(() => {
    const scale = scales.yScale();
    const size = scale.domain()[1] - scale.domain()[0];
    return tickLabel(size, dimensions().margins.right);
  });

  const [svg, setSvg] = createSignal<SVGSVGElement>();

  return (
    <Show when={data().length > 2}>
      <svg
        ref={setSvg}
        viewBox={`0 0 ${dimensions().width} ${dimensions().height}`}
      >
        <AxisBottom />
        <AxisRight labelFormat={format()} />
        <LineSeries />
        <Show when={showTooltip()}>
          <line
            transform={`translate(0, ${dimensions().margins.top})`}
            x1={tooltipData()?.offsetX}
            x2={tooltipData()?.offsetX}
            y1={innerHeight()}
            y2={0}
            stroke='currentColor'
          />
          <circle
            r={4}
            cx={scales.xScale()(tooltipData()?.data.timestamp)}
            cy={scales.yScale()(tooltipData()?.data.value)}
            fill='currentColor'
          />
        </Show>
        <EventEmitter
          ref={(e) => {
            tooltip(e, <ChartTooltip data={tooltipData()?.data} />);
          }}
          onmouseenter={() => setShowTooltip(true)}
          onmouseleave={() => setShowTooltip(false)}
          onmousemove={(e) => {
            const point = new DOMPoint(e.clientX, e.clientY);
            const cursorpoint = point.matrixTransform(
              svg()!.getScreenCTM()!.inverse()
            );
            const xscale = scales.xScale();
            const x = xscale.invert(cursorpoint.x);
            const i = bisectCenter(
              data().map((d) => new Date(d.timestamp)),
              x
            );
            setTooltipData({
              offsetX: cursorpoint.x,
              offsetY: cursorpoint.y,
              i,
              data: data()[i],
            });
          }}
        />
      </svg>
    </Show>
  );
}
