import { Navigate, useNavigate, useSearchParams } from 'solid-app-router';
import {
  createMemo,
  createResource,
  ErrorBoundary,
  onCleanup,
  Show,
  untrack,
  useContext,
} from 'solid-js';

import { LoadingScreen } from '../../components';
import { AuthContext } from '../../userstore';

const OAuthCallback = () => {
  const [{ loaded }, { getSessionSignal, setSessionToken }] =
    useContext<any>(AuthContext);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const oauthApiUrl = 'https://api.drf.rs/auth/oauthcallback';

  async function exchangeCodeForSessionToken([code, state]) {
    const response = await fetch(`${oauthApiUrl}?code=${code}&state=${state}`);

    const result = await response.text();

    if (!response.ok) throw new Error(result);

    setSessionToken(result);

    return result;
  }

  const params = createMemo(() =>
    searchParams.code && searchParams.state
      ? [searchParams.code, searchParams.state]
      : undefined
  );

  if (!params) navigate('/');

  const [sessionToken] = createResource(params, exchangeCodeForSessionToken);

  const redirectDelay = (url, ms = 3000) => {
    const timeout = setTimeout(() => navigate(url), ms);
    onCleanup(() => {
      clearTimeout(timeout);
    });
  };

  return (
    <ErrorBoundary
      fallback={
        <>
          Looks like there was a problem logging in...
          <pre>
            <code>{sessionToken.error.message}</code>
          </pre>
          {redirectDelay('/')}
        </>
      }
    >
      <Show when={!sessionToken()}>
        <Show when={untrack(getSessionSignal())}>
          {(token) => (
            <Show when={token && token !== 'invalid' && loaded === true}>
              <Navigate href={'/dashboard'}></Navigate>
            </Show>
          )}
        </Show>
      </Show>
      <Show when={sessionToken.loading}>
        <LoadingScreen noAnimation />
      </Show>
      <Show when={!sessionToken.loading}>
        Successful login! Redirecting...
        <Navigate href={'/dashboard'}></Navigate>
      </Show>
    </ErrorBoundary>
  );
};

export default OAuthCallback;
