import {
  Avatar,
  Divider,
  HopeProps,
  Icon,
  IconButton,
  Text,
} from '@hope-ui/solid';
import { Show, splitProps, useContext } from 'solid-js';
import type { Component, ComponentProps } from 'solid-js';
import { useNavigate } from 'solid-app-router';
import { Motion } from '@motionone/solid';

import { BsShieldShaded } from 'solid-icons/bs';
import { FiLogOut } from 'solid-icons/fi';
import { RiMapRunLine } from 'solid-icons/ri';
import { IoClose } from 'solid-icons/io';

import { Box, Button } from '../global';
import { generateMenus, Menu, MenuButton, MenuStructure } from '../menu';
import DashboardBranding from './DashboardBranding';
import { AuthContext } from '../../userstore';

export type DashboardMobileMenuProps = {
  menus?: MenuStructure[];
  onClose?: () => void;
} & ComponentProps<'div'> &
  HopeProps;

export const DashboardMobileMenu: Component<DashboardMobileMenuProps> = (
  props
) => {
  const [session] = useContext(AuthContext);

  const navigate = useNavigate();
  const [local, rest] = splitProps(props, ['onClose']);

  const closeMenu = () => {
    if (local.onClose) local.onClose();
  };

  return (
    <Box
      as={Motion.div}
      d='flex'
      flexDirection='column'
      alignItems='start'
      pos='absolute'
      w='100vw'
      h='100vh'
      bg='$sidebarBackground'
      css={{
        backdropFilter: 'blur(4px)',
      }}
      zIndex={1}
      initial={{ background: 'rgba(0,0,0,0)' }}
      animate={{
        background: 'rgba(0,0,0,0.2)',
        transition: { duration: 0.6 },
      }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      {...rest}
    >
      <Box
        as={Motion.div}
        d='flex'
        flexDirection='column'
        w='$full'
        gap='$4'
        p='$4'
        bg='rgba(0,0,0,0.8)'
        borderBottom='1px solid $sidebarBorder'
        initial={{ y: '-400px', opacity: 0 }}
        animate={{ y: 0, opacity: 1, transition: { duration: 0.4 } }}
      >
        <Box d='flex' justifyContent='space-between'>
          <DashboardBranding />

          <Button
            bg='transparent'
            p='$2'
            aria-label='Close Navigation'
            onClick={closeMenu}
          >
            <Icon as={IoClose} transform='scale(1.4)' />
          </Button>
        </Box>

        <Divider />

        <Box d='flex' gap='$2' alignItems='center'>
          <Avatar name='Drumsy' />
          <Box d='flex' flexDirection='column'>
            <Text size='lg'>Hello, {session.user.name}!</Text>
            <Text size='sm' color='$neutral11'>
              Member
            </Text>
          </Box>
          <Box flexGrow={1} />
          <IconButton
            icon={<FiLogOut />}
            aria-label='Logout'
            background='transparent'
            border='1px solid $containerOutline'
          />
        </Box>

        <Show when={props.menus}>{generateMenus(props.menus)}</Show>

        <Menu title='Dashboard'>
          <MenuButton
            onClick={() => {
              window.dispatchEvent(new Event('toggleDevWindow'));
              closeMenu();
            }}
            icon={BsShieldShaded}
          >
            Developer Menu
          </MenuButton>
          <MenuButton
            onClick={() => {
              navigate('/');
            }}
            icon={RiMapRunLine}
          >
            Exit Dashboard
          </MenuButton>
        </Menu>
      </Box>
      {/* Clickable box to close the menu */}
      <Box flex={1} w='$full' onClick={closeMenu} />
    </Box>
  );
};

export default DashboardMobileMenu;
