import { Component, Show, splitProps } from 'solid-js';
import type { DeepReadonly } from 'solid-js/store';
import { Box, Heading, HopeProps, Text } from '@hope-ui/solid';
import { GW2ApiArmorItem, GW2ApiWeaponItem } from 'gw2api-client';

import GW2Coin from './GW2Coin';
import TooltipBackground from '../../assets/tooltip.png';
import GW2ItemDescription from './GW2ItemDescription';
import { Flex } from '../global';
import { ItemData } from '../../lib/drf/drop';

const TooltipText: Component<HopeProps> = (props) => {
  const [local, rest] = splitProps(props, ['children']);
  return (
    <Text fontFamily='$menomonia' fontSize='$sm' lineHeight='1.1rem' {...rest}>
      {local.children}
    </Text>
  );
};

const addSpaces = (text: string): string =>
  `${text}`.replaceAll(/([A-Z])/g, ' $1').trim();

const GW2ItemTooltip: Component<{
  item: DeepReadonly<ItemData>;
}> = (props) => (
  <Box
    p='5px'
    maxW='300px'
    border='2px solid rgba(0, 0, 0, 0.8)'
    background={`url(${TooltipBackground})`}
    style={{
      'text-shadow': '1px 1px 0 rgba(0, 0, 0, 0.8)',
    }}
  >
    <Flex alignItems='center' gap='$2'>
      <img
        src={props.item.api?.icon
          ?.replace(
            'https://render.guildwars2.com/file/',
            'https://icons-gw2.darthmaim-cdn.com/'
          )
          .replace('.png', '-64px.png')}
        width={32}
        height={32}
        crossOrigin="anonymous"
      />

      <Heading
        fontFamily='$menomonia'
        class='text-[11pt] align-middle items-center mb-0'
        // style={{ color: `var(--${props.item?.rarity})` }}
        color={`$${props.item.api?.rarity.toLowerCase()}`}
        lineHeight='1.1rem'
      >
        {props.item.amount} {props.item.api?.name}
      </Heading>
    </Flex>

    <Flex flexDirection='column' gap='$3'>
      <Show
        when={
          props.item.api?.description &&
          props.item.api?.type !== 'Armor' &&
          props.item.api?.type !== 'Weapon'
        }
      >
        <TooltipText>
          <GW2ItemDescription>{props.item.api?.description}</GW2ItemDescription>
        </TooltipText>
      </Show>

      <Box>
        <Show
          when={
            props.item.api?.type === 'Armor' ||
            props.item.api?.type === 'Weapon'
          }
          fallback={
            <TooltipText>{addSpaces(props.item.api?.type)}</TooltipText>
          }
        >
          <TooltipText>{props.item.api?.rarity}</TooltipText>

          <Show when={props.item.api?.type === 'Armor'}>
            <TooltipText>
              {(props.item.api as GW2ApiArmorItem).details.weight_class}
            </TooltipText>
            <TooltipText>
              {addSpaces((props.item.api as GW2ApiArmorItem).details.type)}
            </TooltipText>
          </Show>

          <Show when={props.item.api?.type === 'Weapon'}>
            <TooltipText>
              {addSpaces((props.item.api as GW2ApiWeaponItem).details.type)}
            </TooltipText>
          </Show>
        </Show>

        <Show when={props.item.api?.level > 1}>
          <TooltipText>Required level: {props.item.api.level}</TooltipText>
        </Show>

        <Show
          when={
            props.item.api?.description &&
            (props.item.api?.type === 'Armor' ||
              props.item.api?.type === 'Weapon')
          }
        >
          <TooltipText>
            <GW2ItemDescription>
              {props.item.api.description}
            </GW2ItemDescription>
          </TooltipText>
        </Show>
      </Box>

      <Box>
        <Show
          when={
            props.item.api?.vendor_value &&
            !props.item.api?.flags?.includes('NoSell')
          }
        >
          {() => (
            <TooltipText mt='$1' d='flex' gap='$2'>
              <Box flexShrink={0}>Vendor:</Box>
              <Flex flexWrap='wrap' columnGap='$2'>
                <GW2Coin value={props.item.api.vendor_value} />
                <Show when={props.item.amount !== 1}>
                  <Flex>
                    (
                    <GW2Coin
                      mr='4px'
                      value={
                        (props.item.amount || 1) * props.item.api.vendor_value
                      }
                    />{' '}
                    for {props.item.amount})
                  </Flex>
                </Show>
              </Flex>
            </TooltipText>
          )}
        </Show>
        <Show when={props.item.tp}>
          {(tp) => (
            <>
              <TooltipText d='flex' gap='$2'>
                <Box flexShrink={0}>TP Sell:</Box>
                <Flex flexWrap='wrap' columnGap='$2'>
                  <GW2Coin value={tp.sell} />
                  <Show when={props.item.amount !== 1}>
                    <Flex>
                      (
                      <GW2Coin
                        mr='4px'
                        value={(props.item.amount || 1) * tp.sell}
                      />{' '}
                      for {props.item.amount})
                    </Flex>
                  </Show>
                </Flex>
              </TooltipText>
              <TooltipText d='flex' gap='$2'>
                <Box flexShrink={0}>TP Buy:</Box>
                <Flex flexWrap='wrap' columnGap='$2'>
                  <GW2Coin value={tp.buy} />
                  <Show when={props.item.amount !== 1}>
                    <Flex>
                      (
                      <GW2Coin
                        mr='4px'
                        value={(props.item.amount || 1) * tp.buy}
                      />{' '}
                      for {props.item.amount})
                    </Flex>
                  </Show>
                </Flex>
              </TooltipText>
            </>
          )}
        </Show>
      </Box>
    </Flex>
  </Box>
);

export default GW2ItemTooltip;
