import { Box, Spinner, Text } from '@hope-ui/solid';

export const WaitingData = () => (
  <Box d='flex' textAlign='center' p='$6' flexDirection='column'>
    <Text
      d='flex'
      gap='$4'
      alignItems='center'
      justifyContent='center'
      fontSize='$2xl'
      ml='-$8'
    >
      <Spinner speed='1s' /> Waiting on some data...
    </Text>
    <Text color='$textMuted' fontSize='$xs'>
      Try picking up some items in-game!
    </Text>
  </Box>
);

export default WaitingData;
