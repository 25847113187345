import { Show, splitProps } from 'solid-js';
import type { Component, ComponentProps } from 'solid-js';
import { HopeProps, Icon, Text } from '@hope-ui/solid';

import {
  AiFillCloseCircle,
  AiOutlineExperiment,
  AiOutlineStar,
} from 'solid-icons/ai';
import { FiClock, FiType } from 'solid-icons/fi';
import { ImCoinDollar } from 'solid-icons/im';

import { Button } from '../global';
import type { Filter } from '../../context/DRFContext';
import { ItemRarityFilterOptions } from '../../lib/drf/filtering/createItemRarityFilter';

export type FilterType = 'time' | 'rarity' | 'filter';

const icons = {
  filter: AiOutlineExperiment,
  item: AiOutlineExperiment,
  rarity: AiOutlineStar,
  time: FiClock,
  type: FiType,
  currency: ImCoinDollar,
};

const RarityText = (props: { filter: Filter<ItemRarityFilterOptions> }) => {
  const rarityIndexes = {
    Junk: 1,
    Basic: 2,
    Fine: 3,
    Masterwork: 4,
    Rare: 5,
    Exotic: 6,
    Ascended: 7,
    Legendary: 8,
  };

  const sortedRarities = () =>
    [...props.filter.options.rarities].sort(
      (a, b) => rarityIndexes[a] - rarityIndexes[b]
    );

  const prefix = () => (props.filter.options.exclusionFilter ? 'Hide' : 'Show');
  const content = () =>
    sortedRarities().map((rarity, index) => (
      <Text as='span' fontWeight='$bold' color={`$${rarity.toLowerCase()}`}>
        {rarity}
        {index === sortedRarities().length - 1 ? '' : ', '}
      </Text>
    ));

  return (
    <Text>
      {prefix}: {content}
    </Text>
  );
};

export type FilterChipType = {
  filter: Filter<any>;
} & HopeProps &
  ComponentProps<'div'>;

export const FilterChip: Component<FilterChipType> = (props) => {
  const [local, rest] = splitProps(props, ['filter', 'children']);

  return (
    <Button
      px='$1_5'
      py='$1_5'
      bg='$container'
      fontSize='$sm'
      transition='all 0.08s ease-in-out'
      css={{
        '&:hover .filter-icon': {
          display: 'none',
        },
        '&:hover .filter-close-icon': {
          display: 'inline-block',
        },
      }}
      leftIcon={
        <>
          <Icon
            class='filter-close-icon'
            d='none'
            color='$danger9'
            as={AiFillCloseCircle}
            _hover={{
              color: '$danger8',
            }}
          />
          <Icon
            class='filter-icon'
            color='$accentBlue'
            as={icons[local.filter.type ?? 'filter']}
          />
        </>
      }
      {...rest}
    >
      <Show when={local.filter.type === 'rarity'}>
        <RarityText filter={local.filter} />
      </Show>
    </Button>
  );
};
