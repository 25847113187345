import type { InputStyleConfig } from '@hope-ui/solid';

export const Input: InputStyleConfig = {
  baseStyle: {
    input: {
      fontFamily: '$sans',
    },
  },
};

export default { Input };
