/* eslint-disable max-classes-per-file */
/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import client from 'gw2api-client';

import cacheBrowserStorage from 'gw2api-client/src/cache/browser';

import AbstractEndpoint from 'gw2api-client/src/endpoint';
import ItemsEndpoint from 'gw2api-client/src/endpoints/items';
import CommerceEndpoint from 'gw2api-client/src/endpoints/commerce';

const gw2api = client().autoBatch();

gw2api.cacheStorage(cacheBrowserStorage({ persistDebounce: 100 }));

gw2api.fetch.retry((tries, err) => {
  if (tries > 3) {
    return false;
  }

  const res = err.response;
  if (res && (res.status < 400 || res.status === 403 || res.status === 404)) {
    return false;
  }

  return true;
});

gw2api.fetch.retryWait((tries) => tries * 100);

function requestWithTimeout(urls, type = 'json') {
  urls = urls.map((url) => this._buildUrl(url));

  /* istanbul ignore next */
  const credentials = 'omit';

  return retry(() => {
    const controller = new AbortController();
    const timer = setTimeout(() => controller.abort(), 1000);

    return this.fetch
      .many(urls, { type, credentials, signal: controller.signal })
      .then((data) => {
        clearTimeout(timer);
        return data;
      });
  });
}

async function retry<K>(promise: () => Promise<K>, times = 3): Promise<K> {
  for (let i = 0; i < times - 1; i++) {
    try {
      // this is exactly what we want
      // eslint-disable-next-line no-await-in-loop
      const res = await promise();
      return res;
    } catch {
      // pass
    }
  }
  const res = await promise();
  return res;
}

class PricesEndpoint extends AbstractEndpoint {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  constructor(client) {
    super(client);
    this.url = '/v2/commerce/prices';
    this.isPaginated = true;
    this.isBulk = true;
    this.supportsBulkAll = false;
    this.cacheTime = 60;
  }
}

const items = new ItemsEndpoint(gw2api);
items._requestMany = requestWithTimeout.bind(items);
gw2api.items = () => items;
const commerce = new CommerceEndpoint(gw2api);
commerce._requestMany = requestWithTimeout.bind(commerce);
gw2api.commerce = () => commerce;
const prices = new PricesEndpoint(commerce);
prices._requestMany = requestWithTimeout.bind(prices);
commerce.prices = () => prices;

class Gw2TreasuresItemEndpoint extends AbstractEndpoint {
  constructor(c) {
    super(c);
    this.baseUrl = 'https://api.gw2treasures.com';
    this.url = `/items/bulk/data`;
    this.schemaVersion = '2024-08-13T00:00:00.000Z';
    this.isPaginated = false;
    this.isBulk = true;
    this.supportsBulkAll = false;
    this.isLocalized = true;
    this.cacheTime = 365 * 24 * 60 * 60;
    this.autoBatching = true;
    this.maxPageSize = 1000;

    // Send credentials (e.g. session cookies)
    this.credentials = false;
  }

  // Execute multiple requests in parallel
  _requestMany(urls, type = 'json') {
    urls = urls.map(
      (url) =>
        `${this._buildUrl(url)}&apiKey=672cd837-bc30-4a8f-9139-4076b6a38f1c`
    );

    /* istanbul ignore next */
    const credentials = this.credentials ? 'include' : undefined;

    return this.fetch.many(urls, { type, credentials });
  }
}

const treasuresItems = new Gw2TreasuresItemEndpoint(gw2api);
gw2api.gw2treasuresItem = treasuresItems;

class Gw2TreasuresPriceEndpoint extends AbstractEndpoint {
  constructor(c) {
    super(c);
    this.baseUrl = 'https://api.gw2treasures.com';
    this.url = `/items/bulk/tp-prices`;
    this.schemaVersion = '2024-08-13T00:00:00.000Z';
    this.isPaginated = false;
    this.isBulk = true;
    this.supportsBulkAll = false;
    this.isLocalized = true;
    this.cacheTime = 365 * 24 * 60 * 60;
    this.autoBatching = true;
    this.maxPageSize = 1000;

    // Send credentials (e.g. session cookies)
    this.credentials = false;
  }

  // Execute multiple requests in parallel
  _requestMany(urls, type = 'json') {
    urls = urls.map(
      (url) =>
        `${this._buildUrl(url)}&apiKey=672cd837-bc30-4a8f-9139-4076b6a38f1c`
    );

    /* istanbul ignore next */
    const credentials = this.credentials ? 'include' : undefined;

    return this.fetch.many(urls, { type, credentials });
  }
}

const treasuresPrices = new Gw2TreasuresPriceEndpoint(gw2api);
gw2api.gw2treasuresPrice = treasuresPrices;

export default gw2api;
