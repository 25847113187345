import { Box, Divider, Heading, useTheme } from '@hope-ui/solid';
import { createBreakpoints } from '@solid-primitives/media';
import { Component, For, Show } from 'solid-js';
import type { DeepReadonly } from 'solid-js/store';

// import { globalCss } from '@stitches/core';
// // import { TransitionGroup } from 'solid-transition-group';
// import { TransitionGroup } from 'solid-transition-group';
// import { Key } from '@solid-primitives/keyed';
import { SessionSummary as DRFSessionSummary } from '../../lib/drf/summary';
import GW2Currency from '../gw2/GW2Currency';
import GW2Item from '../gw2/GW2Item';
import NoUserData from './NoUserData';

export type SessionSummaryProps = {
  summary: DeepReadonly<DRFSessionSummary>;
};

export const SessionSummary: Component<SessionSummaryProps> = (props) => {
  const theme = useTheme();
  const matches = createBreakpoints({
    lg: `${theme().sizes.containerLg.value}`,
  });

  return (
    <Show
      when={props.summary.currencies.length || props.summary.items.length}
      fallback={<NoUserData />}
    >
      <Box d='flex' flexDirection='column' gap='$6'>
        <Show when={props.summary.currencies.length > 0 && !matches.lg}>
          <Box>
            <Heading fontSize='$lg'>Currencies</Heading>
            <Divider color='$containerOutline' mb='$2' />
            <For each={props.summary.currencies}>
              {(currency) => (
                <GW2Currency id={currency.id} amount={currency.amount} />
              )}
            </For>
          </Box>
        </Show>

        <Show when={props.summary.items.length > 0}>
          <Box>
            <Heading fontSize='$lg'>Items</Heading>
            <Divider color='$containerOutline' mb='$3' />
            <Box d='flex' flexWrap='wrap'>
              {/* <TransitionGroup name='item-view-shuffle'>
                <Key each={props.summary.items} by={(x) => x.id}> */}
              <For each={props.summary.items}>
                {(item) => <GW2Item item={item} />}
              </For>
              {/* </Key>
              </TransitionGroup> */}
            </Box>
          </Box>
        </Show>
      </Box>
    </Show>
  );
};

export default SessionSummary;
