import { Box, Divider, Heading } from '@hope-ui/solid';
import type { HopeProps } from '@hope-ui/solid';
import { For, Match, Show, splitProps, Switch } from 'solid-js';
import type { JSX } from 'solid-js';
import { IconTypes } from 'solid-icons';
import { MenuLink } from './MenuLink';
import { MenuButton } from './MenuButton';

export type MenuStructure = {
  title: string;
  items: {
    icon: IconTypes;
    text: string;
    href?: string;
    selectionMatcher?: string | string[];
    collapsed?: boolean;
    onClick?: () => void;
  }[];
};

export type MenuProps = {
  title?: string;
  children?: JSX.Element;
  headerProps?: HopeProps;
  listProps?: HopeProps;
  collapsed?: boolean;
} & HopeProps;

export const Menu = (props: MenuProps) => {
  const [local, headerProps, listProps, rest] = splitProps(
    props,
    ['children', 'title', 'collapsed'],
    ['headerProps'],
    ['listProps']
  );

  return (
    <Box {...rest}>
      <Show when={!local.collapsed}>
        <Heading
          fontWeight='$light'
          color='$sidebarMenuTitle'
          px='$2'
          mb='$1'
          {...headerProps}
        >
          {local.title ?? 'Menu'}
        </Heading>
      </Show>
      <Box d='flex' flexDirection='column' gap='$2' {...listProps}>
        {local.children}
      </Box>
    </Box>
  );
};

export const generateMenus = (menus: MenuStructure[], collapsed?: boolean) => (
  <For each={menus}>
    {(menu, index) => (
      <Menu title={menu.title} collapsed={collapsed}>
        <For each={menu.items}>
          {(item) => (
            <>
              <Switch>
                <Match when={item.href}>
                  <MenuLink
                    icon={item.icon}
                    href={item.href}
                    selectionMatcher={item.selectionMatcher}
                    collapsed={collapsed}
                  >
                    {item.text}
                  </MenuLink>
                </Match>
                <Match when={item.onClick}>
                  <MenuButton
                    icon={item.icon}
                    onClick={item.onClick}
                    collapsed={collapsed}
                  >
                    {item.text}
                  </MenuButton>
                </Match>
              </Switch>
            </>
          )}
        </For>
        <Show when={collapsed && index() + 1 !== menus.length}>
          <Divider />
        </Show>
      </Menu>
    )}
  </For>
);
