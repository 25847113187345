import { Text } from '@hope-ui/solid';
import { Component, createMemo, JSXElement } from 'solid-js';

function renderDescription(text: string): JSXElement {
  const TEMPLATE_REGEX = /<br>|<c[=@][@=]?([^>]+)>([^]*?)(<\/?c\/?>|$)/g;
  // eslint-disable-next-line solid/reactivity
  const matches = text.matchAll(TEMPLATE_REGEX);
  let lastIndex = 0;
  let el = <></>;

  for (const match of matches) {
    if (match[0] === '<br>') {
      el = (
        <>
          {el}
          {text.substring(lastIndex, match.index)}
          <br />
        </>
      );
    } else {
      el = (
        <>
          {el}
          {text.substring(lastIndex, match.index)}
          <Text fontFamily='$menomonia' color={`$${match[1]}`}>
            {renderDescription(match[2])}
          </Text>
        </>
      );
    }
    lastIndex = match.index + match[0].length;
  }

  el = (
    <>
      {el}
      {text.substring(lastIndex)}
    </>
  );
  return el;
}

const GW2Item: Component = (props) => {
  const v = createMemo(() => renderDescription(props.children as string));
  return <>{v}</>;
};

export default GW2Item;
