export const globalColors = {
  junk: '#AAAAAA',
  basic: '#FFFFFF',
  fine: '#5599FF',
  masterwork: '#33CC11',
  rare: '#FFDD22',
  exotic: '#FFAA00',
  ascended: '#FF4488',
  legendary: '#9933FF',
  // gw2 coins
  gold: '#ddbb44',
  silver: '#999898',
  copper: '#bb6622',
  // gw2 wallet currency colors
  gems: '#66aaff',
  karma: '#dd44cc',
  laurels: '#43d041',
  // gw2 flavor colors
  flavor: '#99eedd',
  reminder: '#999999',
  warning: '#D94130',
  abilitytype: '#CCAA00',
};

export const lightColors = {};

export const darkColors = {};
