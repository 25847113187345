import { Input as HopeInput } from '@hope-ui/solid';
import type { InputElementProps } from '@hope-ui/solid';
import type { Component, ComponentProps } from 'solid-js';

export type InputProps = {} & InputElementProps & ComponentProps<'input'>;

export const Input: Component<InputProps> = (props) => (
  <HopeInput
    bg='$container'
    border='none'
    outline='0 solid $accentBlue'
    _focus={{
      border: 'none',
      outline: '2px solid $accentBlue',
      bg: 'transparent',
    }}
    transition='all 0.04s ease-in-out'
    {...props}
  />
);
