import {
  Accessor,
  Component,
  Setter,
  batch,
  createContext,
  createEffect,
  createSignal,
  untrack,
  useContext,
} from 'solid-js';
import { Store, createStore, reconcile } from 'solid-js/store';

// eslint-disable-next-line import/no-extraneous-dependencies
import IngestWorker from 'worker-iife:../workers/ingest.worker';
import FilterWorker from 'worker-iife:../workers/filter.worker';
import FilterjoinWorker from 'worker-iife:../workers/filterjoin.worker';
import TimelineWorker from 'worker-iife:../workers/timeline.worker';
import SummaryWorker from 'worker-iife:../workers/summary.worker';
import CacheSharedWorker from 'sharedWorker-iife:../workers/cache.worker';

import { AuthContext } from '../userstore';

import DropBuffer from '../lib/drf/sync/dropbuffer';
import {
  FILTERJOIN_HEAD,
  FILTER_HEADS,
  SYNC_BUFFER_SIZE,
  MAX_WORKER,
  SUMMARY_HEAD,
  TIMELINE_HEAD,
  WRITE_HEAD,
  SYNC_BUFFER_LENGTH,
  FILTERJOIN_INDEX,
} from '../lib/drf/sync/constants';
import { Filter } from './DRFContext';
import { SessionStats, SessionSummary } from '../lib/drf/summary';
import DropFactory, {
  MarshalSessionDropData,
  SessionDropData,
} from '../lib/drf/drop';
import Gw2Worker from '../lib/gw2worker';

export const MIN_SLICE_AMOUNT = 20;
export const DataContext = createContext<DataContextType>();

export type DataContextType = {
  state: Store<DataContextState>;
  currentSlice: Accessor<number>;
  setCurrentSlice: Setter<number>;
  highlightValue: Accessor<number>;
  setHighlightValue: Setter<number>;
  internalState: any;
  resetWorkers: () => void;
  desync: Accessor<boolean>;
  exportSummary: () => void;
  exportTimeline: any;
  addFilter: any;
  removeFilter: any;
};

export type DataContextState = {
  events: SessionDropData[];
  totalEvents: number;
  summary: SessionSummary;
  sessionStats: SessionStats;
  filters: Filter<any>[];
  approxMem: number;
};

export const DataProvider: Component = (props) => {
  const [, { getSessionSignal, getCharacterResource }] =
    useContext(AuthContext);

  const [currentSlice, setCurrentSlice] = createSignal(MIN_SLICE_AMOUNT);

  const itemBuffer = new SharedArrayBuffer(DropBuffer.BUFFER_SIZE);
  const dropBuffer = new DropBuffer(itemBuffer);
  const outputs = new SharedArrayBuffer(SYNC_BUFFER_SIZE);
  const sync = new Int32Array(outputs);
  const resetBuffer = new SharedArrayBuffer(SYNC_BUFFER_LENGTH);
  const resets = new Uint8Array(resetBuffer);
  const ingest = new IngestWorker();
  const workerCount = Math.min(
    window.navigator.hardwareConcurrency,
    MAX_WORKER
  );

  const apiCachePort = new CacheSharedWorker();
  const apiCache = new Gw2Worker(apiCachePort.port);
  const dropFactory = new DropFactory(dropBuffer, [], apiCache);

  if (import.meta.env.VITE_CI_COMMIT_REF_SLUG !== 'master') {
    let oldLogs = [];

    setInterval(() => {
      const logs = [];
      logs.push(sync[WRITE_HEAD]);
      for (let i = 0; i < workerCount; ++i) {
        logs.push(sync[FILTER_HEADS + i]);
      }
      logs.push(sync[FILTERJOIN_HEAD]);
      logs.push(sync[TIMELINE_HEAD]);
      logs.push(sync[SUMMARY_HEAD]);

      let equal = true;
      let reset = false;
      for (let i = 0; i < logs.length; i++) {
        if (oldLogs[i] !== logs[i]) {
          equal = false;
        }
        if (oldLogs[i] > logs[i]) {
          reset = true;
        }
      }

      if (reset) {
        // eslint-disable-next-line no-console
        console.log('RESET');
      }
      if (!equal) {
        // eslint-disable-next-line no-console
        console.log(logs.join(', '));
        oldLogs = logs;
      }
    }, 100);
  }

  const bufferSize = 4 * (Math.floor(DropBuffer.MAX_INDEX / workerCount) + 1);
  const filterWorkers = [];
  const workerBuffers = [];
  for (let i = 0; i < workerCount; i++) {
    const worker = new FilterWorker();

    filterWorkers.push(worker);
    workerBuffers.push(new SharedArrayBuffer(bufferSize));
  }

  // todo: add filter management

  // todo: handle onmessage from ingest worker
  const resetWorkers = () => {
    for (let i = 0; i < SYNC_BUFFER_LENGTH; i++) {
      Atomics.store(resets, i, 1);
      Atomics.notify(sync, i);
    }
  };

  const filterjoinBuffer = new SharedArrayBuffer(DropBuffer.MAX_INDEX * 4);
  const filteredItems = new Int32Array(filterjoinBuffer);
  const filterjoin = new FilterjoinWorker();

  filterjoin.postMessage({
    outputs,
    buffer: filterjoinBuffer,
    count: workerCount,
    workerBuffers,
    resetBuffer,
  });

  const summary = new SummaryWorker();
  const summarySharedWorker = new CacheSharedWorker();
  summarySharedWorker.port.start();

  summary.postMessage(
    {
      outputs,
      buffer: itemBuffer,
      inputBuffer: filterjoinBuffer,
      resetBuffer,
      port: summarySharedWorker.port,
    },
    [summarySharedWorker.port]
  );

  const timeline = new TimelineWorker();
  const timelineSharedWorker = new CacheSharedWorker();
  timelineSharedWorker.port.start();

  timeline.postMessage(
    {
      itemBuffer,
      sync: outputs,
      joinBuffer: filterjoinBuffer,
      resetBuffer,
      port: timelineSharedWorker.port,
    },
    [timelineSharedWorker.port]
  );

  createEffect(() => {
    timeline.postMessage({ currentSlice: currentSlice() });
    return Atomics.notify(sync, FILTERJOIN_HEAD);
  });

  createEffect(() => {
    if (getCharacterResource().loading) {
      return;
    }

    const characters = getCharacterResource()();
    const auth = untrack(getSessionSignal());
    dropBuffer.set(characters, itemBuffer, outputs);

    timeline.postMessage({ characters });
    ingest.postMessage({
      dataToken: auth,
      characters,
      buffer: itemBuffer,
      outputs,
      resetBuffer,
    });

    const workerCaches = [];
    for (let i = 0; i < workerCount; i++) {
      const worker = new CacheSharedWorker();
      worker.port.start();
      workerCaches.push(worker);
      filterWorkers[i].postMessage(
        {
          characters,
          itemBuffer,
          outputs,
          buffer: workerBuffers[i],
          id: i,
          count: workerCount,
          resetBuffer,
          port: worker.port,
        },
        [worker.port]
      );
    }
  });

  const [state, setState] = createStore<DataContextState>({
    events: [],
    totalEvents: 0,
    summary: { items: [], currencies: [] },
    sessionStats: {
      profit: 0,
      goldPerHour: 0,
      tpProfit: 0,
      tpPerHour: 0,
      lastUpdate: 0,
    },
    filters: [],
    approxMem: 0,
  });

  const [desync, setDesync] = createSignal(false);
  const debounce = (
    fn: (...args) => void,
    ms?: number,
    eager: boolean = false
  ) => {
    let i;
    return (...args) => {
      if (eager) {
        if (!i) {
          fn(...args);
          i = setTimeout(() => {
            i = undefined;
            fn(...args);
          }, ms);
        }
        return;
      }

      if (i) {
        clearTimeout(i);
      }
      i = setTimeout(() => fn(...args), ms);
    };
  };
  const checkDesync = debounce(
    () => {
      setDesync(
        Atomics.load(sync, TIMELINE_HEAD) !== Atomics.load(sync, SUMMARY_HEAD)
      );
    },
    100,
    true
  );

  summary.onmessage = (
    msg: MessageEvent<[SessionSummary | null, SessionStats]>
  ) =>
    batch(() => {
      const [summ, stat] = msg.data;
      if (summ) {
        setState(
          'summary',
          'items',
          reconcile(summ.items, { key: 'id', merge: true })
        );
        setState('summary', 'currencies', summ.currencies);
      }
      setState('sessionStats', stat);
      checkDesync();
    });

  let timer;
  let lastUpdate = Date.now();
  timeline.onmessage = async ({ data }) => {
    cancelAnimationFrame(timer);
    const now = Date.now();
    const [evts, total]: [MarshalSessionDropData[], number] = data;
    const events = await dropFactory.hydrateSessionDropData(evts);

    const update = () => {
      lastUpdate = now;
      setState('events', reconcile(events, { merge: true, key: 'timestamp' }));
      setState('totalEvents', total);
      checkDesync();
    };

    if (now > lastUpdate + 1000) {
      update();
    } else {
      timer = requestAnimationFrame(update);
    }
  };

  const downloadFile = (csvdata, name) => {
    const blob = new Blob([csvdata], { type: 'text/csv' });

    const a: HTMLAnchorElement = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = `drf-${name}-${Date.now().valueOf()}.csv`;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const exportTimeline = () => {
    let csvdata = 'timestamp,character,magic_find,item_id,currency_id,amount\n';

    for (let i = Atomics.load(sync, FILTERJOIN_INDEX) - 1; i >= 0; i--) {
      const idx = filteredItems[i];
      const timestamp = new Date(Number(dropBuffer.dropTime[idx]));
      const character = dropBuffer.characters[dropBuffer.dropChar[idx]];
      const mf = dropBuffer.dropMf[idx];
      const item = dropBuffer.dropItem[idx];
      const it = item === 0 ? '' : item.toString();
      const curr = dropBuffer.dropCurr[idx];
      const cu = curr === 0 ? '' : curr.toString();
      const count = dropBuffer.dropCount[idx];
      csvdata += `${timestamp.toISOString()},${character},${mf},${it},${cu},${count}\n`;
    }

    downloadFile(csvdata, 'timeline');
  };

  const exportSummary = () => {
    let csvdata = 'item_id,item_name,item_amount,currency_id,currency_amount\n';

    const lines = [];

    for (let i = 0; i < state.summary.items.length; i++) {
      const item = state.summary.items[i];
      lines.push([
        item.id,
        item.api ? item.api.name : '?',
        item.amount,
        '',
        '',
      ]);
    }

    for (let i = state.summary.currencies.length - 1; i >= 0; i--) {
      const currency = state.summary.currencies[i];
      const line = lines[i] ?? ['', '', '', '', '', ''];
      line[3] = currency.id;
      line[4] = currency.amount;
      if (!lines[i]) {
        lines.push(line);
      }
    }

    for (const line of lines) {
      csvdata += `${line.join(',')}\n`;
    }

    downloadFile(csvdata, 'summary');
  };

  const [highlightValue, setHighlightValue] = createSignal(null);

  return (
    <DataContext.Provider
      value={{
        state,
        internalState: [],

        desync,
        resetWorkers,
        exportSummary,
        exportTimeline,
        addFilter: () => {},
        removeFilter: () => {},
        currentSlice,
        setCurrentSlice,
        highlightValue,
        setHighlightValue,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default DataProvider;
