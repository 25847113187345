import { Show, splitProps } from 'solid-js';
import type { Component, ComponentProps } from 'solid-js';

import { Link as SolidLink } from 'solid-app-router';
import type { LinkProps as SolidLinkProps } from 'solid-app-router';

import { Icon, Text } from '@hope-ui/solid';
import type { HopeProps } from '@hope-ui/solid';

import { RiSystemExternalLinkFill } from 'solid-icons/ri';

export type LinkProps = {
  noExternalIcon?: boolean;
} & SolidLinkProps &
  HopeProps &
  ComponentProps<'a'>;

export const Link: Component<LinkProps> = (props) => {
  const [local, rest] = splitProps(props, ['children', 'noExternalIcon']);
  const url = () => new URL(rest.href);

  return (
    <Text
      as={SolidLink}
      textDecoration='underline'
      _hover={{
        color: '$accentBlue',
      }}
      {...rest}
    >
      {local.children}
      <Show
        when={
          rest.href.startsWith('http') &&
          !url().hostname.endsWith('drf.rs') &&
          !local.noExternalIcon
        }
      >
        <Icon
          as={RiSystemExternalLinkFill}
          boxSize='0.65em'
          transform='translateY(-0.1em)'
          ml='1px'
        />
      </Show>
    </Text>
  );
};

export default Link;
