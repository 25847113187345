import { createMemo, For, JSX } from 'solid-js';
import { useChart } from './ChartContext';

export type AxisRightProps = {
  pixelsPerTick?: number;
  numberOfTicks?: number;
  labelFormat?: (v: number) => JSX.Element;
};

export default function AxisRight(props: AxisRightProps) {
  const { scales, dimensions } = useChart()!;
  const range = () => scales.yScale().range();

  const ticks = createMemo(() => {
    const scale = scales.yScale();
    const r = range();
    const height = r[0] - r[1];
    const pixelsPerTick = props.pixelsPerTick ?? 30;
    const numberOfTicks =
      props.numberOfTicks ?? Math.max(1, Math.floor(height / pixelsPerTick));

    const format =
      props.labelFormat ??
      ((v?) => (
        <text
          fill='currentColor'
          style={{
            'font-size': '12px',
            'dominant-baseline': 'middle',
            transform: 'translateX(10px)',
          }}
        >
          {'tickFormat' in scale ? scale.tickFormat()(v) : v?.toString()}
        </text>
      ));

    return scale.ticks(numberOfTicks).map((v) => ({
      v: format(v),
      yOffset: scale(v),
    }));
  });
  const d = () =>
    ['M', 6, range()[0], 'h', -6, 'V', range()[1], 'h', 6].join(' ');

  return (
    <g
      transform={`translate(${
        dimensions().width - dimensions().margins.right
      }, 0)`}
    >
      <path d={d()} fill='none' stroke='currentColor' />
      <For each={ticks()}>
        {({ v, yOffset }) => (
          <g transform={`translate(0, ${yOffset})`}>
            <line x2={6} stroke='currentColor' />
            {v}
          </g>
        )}
      </For>
    </g>
  );
}
