import { Heading, Icon, Text } from '@hope-ui/solid';
import type { HopeProps } from '@hope-ui/solid';
import { Component, ComponentProps, splitProps } from 'solid-js';

import AppIcon from '../../assets/appIcon.svg';
import { Box } from '../global';

export type DashboardBrandingProps = {
  collapsed?: boolean;
} & ComponentProps<'div'> &
  HopeProps;

export const DashboardBranding: Component<DashboardBrandingProps> = (props) => {
  const [local, rest] = splitProps(props, ['collapsed', 'children']);

  return (
    <Box
      d='flex'
      gap='$3'
      alignItems='center'
      justifyContent={local.collapsed ? 'center' : 'start'}
      mb={{ '@initial': undefined, '@lg': local.collapsed ? '$6' : '$4' }}
      {...rest}
    >
      <Icon boxSize='$8' as={AppIcon} />
      <Box display={local.collapsed ? 'none' : 'inline-block'}>
        <Heading
          size='xl'
          fontWeight='$normal'
          fontFamily='$sans'
          lineHeight='1.55rem'
        >
          Drop Research Facilities
        </Heading>
        <Text color='$accentBlue' fontSize='$xs' lineHeight='0.5rem'>
          Beta
        </Text>
      </Box>
    </Box>
  );
};

export default DashboardBranding;
