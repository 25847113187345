// eslint-disable-next-line import/no-extraneous-dependencies
import { bisector } from 'd3-array';

export function binaryInsertion(arr: any[], element, accessor_or_comparator?) {
  accessor_or_comparator = accessor_or_comparator ?? ((i) => i);
  const bisect = bisector(accessor_or_comparator).left;
  arr.splice(bisect(arr, element), 0, element);
}

// custom implementation of Promise.withResolvers (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/withResolvers) because thats really new
export function createPromiseWithResolvers<T>(): PromiseWithResolvers<T> {
  let resolve: (value: T) => void;
  let reject: () => void;

  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return { promise, resolve, reject };
}
