import {
  Accessor, createEffect, createSignal, Setter,
} from 'solid-js';
import { Store, SetStoreFunction, createStore } from 'solid-js/store';

interface ObjectStore {
  [key: string]: Setter<any> | SetStoreFunction<any>;
}

const registeredKeys: ObjectStore = {};

window.onstorage = (e: StorageEvent) => (registeredKeys[e.key] as any)?.(JSON.parse(e.newValue));

export function createLocalSignal<T>(key: string): [Accessor<T>, Setter<T>] {
  const [get, set]: [Accessor<T>, Setter<T>] = createSignal();
  const v = localStorage[key];
  if (v && v !== 'undefined') set(JSON.parse(v));
  createEffect(() => { localStorage[key] = JSON.stringify(get()); });
  registeredKeys[key] = set as Setter<any>;
  return [get, set];
}

export function cleanupLocalstorage(key: string) {
  registeredKeys[key] = null;
}

export function createLocalStore<T>(key: string, init: T): [Store<T>, SetStoreFunction<T>] {
  const [get, set] = createStore(init);
  const v = localStorage[key];
  if (v && v !== 'undefined') set(JSON.parse(v));
  createEffect(() => { localStorage[key] = JSON.stringify(get); });
  registeredKeys[key] = set as SetStoreFunction<any>;
  return [get, set];
}
