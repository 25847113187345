import { JSX } from 'solid-js';
import { useChart } from './ChartContext';

export default function EventEmitter(
  props: JSX.RectSVGAttributes<SVGRectElement>
) {
  const { dimensions, innerHeight, innerWidth } = useChart()!;

  return (
    <rect
      {...props}
      x={dimensions().margins.left}
      y={dimensions().margins.top}
      width={innerWidth()}
      height={innerHeight()}
      fill='none'
      pointer-events='all'
    />
  );
}
