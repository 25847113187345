import { Box, Container, Flex, Heading, Text } from '@hope-ui/solid';
import IndexLayout from '../layouts/IndexLayout';

import AddressImage from '../assets/legal/address.png';
import EmailImage from '../assets/legal/email.png';
import NumberImage from '../assets/legal/number.png';
import { Image } from '../components';

export const LegalNotice = () => {
  return (
    <IndexLayout>
      <Container d='flex' flexDirection='column' gap='$4' mb='$16' px='$4'>
        <Heading
          level={1}
          size='3xl'
          pb='$2'
          borderBottom='2px solid $accentBlue'
        >
          Legal Notice
        </Heading>

        <Text fontWeight='$bold'>
          NCSOFT, the interlocking NC logo, ArenaNet, Guild Wars, Guild Wars
          Factions, Guild Wars Nightfall, Guild Wars: Eye of the North, Guild
          Wars 2, and all associated logos and designs are trademarks or
          registered trademarks of NCSOFT Corporation. All other trademarks are
          the property of their respective owners.
        </Text>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Information according to &sect; 5 TMG</Heading>
          {/* <!-- @Drumms: Convert the address, email address, and phone number to an image, please. Prevents scraping since I don't feel like getting more spam than necessary. -->
          <!-- Also we'll need a noindex in the robots.txt for the legal docs --> */}
          <Box mt='4px'>
            <Image src={AddressImage} crossOrigin='anonymous' />
          </Box>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Contact</Heading>
          <Box>
            <Flex alignItems='center' gap='$1'>
              <Text>Email:</Text>
              <Box mb='-3px'>
                <Image src={EmailImage} crossOrigin='anonymous' />
              </Box>
            </Flex>
            <Flex alignItems='center' gap='$1'>
              <Text>Phone:</Text>
              <Box mb='-2px'>
                <Image src={NumberImage} crossOrigin='anonymous' />
              </Box>
            </Flex>
          </Box>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Liability for content</Heading>
          <Text>
            We make every effort to keep the information on our website current,
            but accept no liability whatsoever for the content provided.
            Pursuant to §7 par. 1 of TMG (German Tele-Media Act), the law limits
            our responsibility as a service provider to our own content on these
            web pages. According to §§8 to 10 of TMG, we are not obligated to
            monitor third party information provided or stored on our website or
            to investigate circumstances that indicate illegal activity.
            Obligations to remove or block the use of information under general
            law remain unaffected. However, liability in this regard is only
            possible from the moment of knowledge of a specific infringement.
            Upon notification of appropriate violations, we will remove this
            content immediately.
          </Text>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Liability for links</Heading>
          <Text>
            Our site contains links to third-party websites. We have no
            influence whatsoever on the information on these Web sites and
            accept no guaranty for its correctness. The content of such
            third-party sites is the responsibility of the respective
            owners/providers. No violations were evident to us at the time of
            linking. We will promptly delete a link upon becoming aware that it
            violates the law.
          </Text>
        </Flex>

        <Flex flexDirection='column' gap='$1'>
          <Heading size='2xl'>Copyright</Heading>
          <Text>
            The content and works provided on these webpages are governed by the
            copyright laws of Germany. Duplication, processing, distribution, or
            any form of commercialisation of such material beyond the scope of
            the copyright law shall require the prior written consent of its
            respective author or creator.
          </Text>
        </Flex>
      </Container>
    </IndexLayout>
  );
};

export default LegalNotice;
