import { WRITE_HEAD } from './constants';

export default class DropBuffer {
  static BUFFER_SIZE = 0x10000000;

  // 29 is currently the byte count for each row
  static MAX_INDEX = Math.floor(DropBuffer.BUFFER_SIZE / 29);
  // initialize from highest amount of bytes to lowest because of alignment
  static OFFSET_Time = 0;
  static OFFSET_Count = DropBuffer.OFFSET_Time + DropBuffer.MAX_INDEX * 8;
  static OFFSET_Index = DropBuffer.OFFSET_Count + DropBuffer.MAX_INDEX * 8;
  static OFFSET_Item = DropBuffer.OFFSET_Index + DropBuffer.MAX_INDEX * 4;
  static OFFSET_Char = DropBuffer.OFFSET_Item + DropBuffer.MAX_INDEX * 4;
  static OFFSET_Mf = DropBuffer.OFFSET_Char + DropBuffer.MAX_INDEX * 2;
  static OFFSET_Curr = DropBuffer.OFFSET_Mf + DropBuffer.MAX_INDEX * 2;

  /// buffer must be at least of size BUFFER_SIZE
  constructor(buffer?: SharedArrayBuffer) {
    this.buffer = buffer;
    this.index = 0;
    this.setProjections();
  }

  /// buffer must be at least of size BUFFER_SIZE
  set(
    characters: string[],
    buffer?: SharedArrayBuffer,
    output?: SharedArrayBuffer
  ) {
    this.buffer =
      buffer ?? this.buffer ?? new SharedArrayBuffer(DropBuffer.BUFFER_SIZE);
    this.sync = new Int32Array(output);
    this.setProjections();

    // find the index of every old character in the new character list
    const conversion = this.characters.map((character) =>
      characters.findIndex((x) => x === character)
    );

    // update indices to new character list
    for (let i = 0; i < this.index; ++i) {
      const oldIndex = Atomics.load(this.dropChar, i);
      Atomics.store(
        this.dropChar,
        i,
        oldIndex === -1 ? -1 : conversion[oldIndex]
      );
    }

    this.characters = characters;
  }

  characters: string[] = [];
  private buffer: SharedArrayBuffer;
  private index: number;
  private sync: Int32Array;

  dropIndex: Uint32Array;
  dropChar: Int16Array;
  dropTime: BigUint64Array;
  dropMf: Uint16Array;
  dropItem: Uint32Array;
  dropCurr: Uint8Array;
  dropCount: BigInt64Array;

  writeDrop(payload: DropPayload) {
    const idx =
      this.index === 0 ? 0 : Atomics.load(this.dropIndex, this.index - 1) + 1;
    const char = this.characters.findIndex((x) => x === payload.character);
    const timestamp = new Date(payload.drop.timestamp).valueOf();

    // write items
    for (const [item, count] of Object.entries(payload.drop.items)) {
      this.insertCommonData(idx, char, timestamp, payload.drop.mf, count);

      Atomics.store(this.dropItem, this.index, Number(item));

      this.index += 1;
    }

    // write currencies
    for (const [curr, count] of Object.entries(payload.drop.curr)) {
      this.insertCommonData(idx, char, timestamp, payload.drop.mf, count);

      Atomics.store(this.dropCurr, this.index, Number(curr));

      this.index += 1;
    }

    // update write head
    Atomics.store(this.sync, WRITE_HEAD, this.index);
    Atomics.notify(this.sync, WRITE_HEAD);
  }

  private insertCommonData(idx, char, timestamp, mf, count) {
    Atomics.store(this.dropIndex, this.index, idx);
    Atomics.store(this.dropChar, this.index, char);
    Atomics.store(this.dropTime, this.index, BigInt(timestamp));
    Atomics.store(this.dropMf, this.index, mf);
    Atomics.store(this.dropCount, this.index, BigInt(count));
  }

  private setProjections() {
    this.dropIndex = new Uint32Array(
      this.buffer,
      DropBuffer.OFFSET_Index,
      DropBuffer.MAX_INDEX
    );
    this.dropChar = new Int16Array(
      this.buffer,
      DropBuffer.OFFSET_Char,
      DropBuffer.MAX_INDEX
    );
    this.dropTime = new BigUint64Array(
      this.buffer,
      DropBuffer.OFFSET_Time,
      DropBuffer.MAX_INDEX
    );
    this.dropMf = new Uint16Array(
      this.buffer,
      DropBuffer.OFFSET_Mf,
      DropBuffer.MAX_INDEX
    );
    this.dropItem = new Uint32Array(
      this.buffer,
      DropBuffer.OFFSET_Item,
      DropBuffer.MAX_INDEX
    );
    this.dropCurr = new Uint8Array(
      this.buffer,
      DropBuffer.OFFSET_Curr,
      DropBuffer.MAX_INDEX
    );
    this.dropCount = new BigInt64Array(
      this.buffer,
      DropBuffer.OFFSET_Count,
      DropBuffer.MAX_INDEX
    );
  }
}

export type DropPayload = { character: string; drop: Drop };

export type IdAmountMap = {
  [key: number]: number;
};

export type Drop = {
  timestamp: string;
  items: IdAmountMap;
  curr: IdAmountMap;
  mf: number;
};
