import {
  Avatar,
  Box,
  globalCss,
  Icon,
  IconButton,
  Menu as HopeMenu,
  MenuContent as HopeMenuContent,
  MenuItem as HopeMenuItem,
  MenuTrigger as HopeMenuTrigger,
  Text,
  useTheme,
} from '@hope-ui/solid';
import { Link, useNavigate } from 'solid-app-router';
import { createEffect, createSignal, Show, useContext } from 'solid-js';
import type { Component } from 'solid-js';
import { Motion, Presence } from '@motionone/solid';
import { createBreakpoints } from '@solid-primitives/media';

import { AiOutlineHome } from 'solid-icons/ai';
import { BiSolidUser } from 'solid-icons/bi';
import { BsShieldShaded } from 'solid-icons/bs';
import { FiLogOut, FiMenu } from 'solid-icons/fi';
import { IoSettingsSharp } from 'solid-icons/io';
import { RiMapRunLine, RiMediaRecordCircleLine } from 'solid-icons/ri';

import AppIcon from '../assets/appIcon.svg';
import { Button, DevGate, Flex, LoadingScreen } from '../components';
import { AuthContext } from '../userstore';
import { DashboardMobileMenu, DashboardSidebar } from '../components/dashboard';

export const DashboardLayout: Component<{
  title?: string;
  name?: string;
}> = (props) => {
  const [session, { logout, getSessionSignal }] = useContext(AuthContext);
  const navigate = useNavigate();

  const applyGlobalCss = globalCss({
    body: {
      background: '$background',
      backgroundAttachment: 'fixed',
    },
  });

  applyGlobalCss();

  // Desktop Sidebar Vars
  const [sidebarRef, setSidebarRef] = createSignal<HTMLDivElement>(null);
  const [sidebarWidth, setSidebarWidth] = createSignal();

  const sidebarResize = () => {
    const ref = sidebarRef();
    if (!ref) return;

    const newWidth = ref.clientWidth;
    setSidebarWidth(newWidth);
  };

  createEffect(() => {
    if (sidebarRef()) sidebarResize();
  });

  // Mobile Menu Vars
  const theme = useTheme();
  const matches = createBreakpoints({
    lg: `${theme().sizes.containerLg.value}`,
  });
  const [mobileMenuOpen, setMobileMenuOpen] = createSignal(false);

  createEffect(() => {
    if (matches.lg) {
      setMobileMenuOpen(false);
      sidebarResize();
    }
  });

  createEffect(() => {
    const sessionToken = getSessionSignal();
    const { loaded } = session;

    if (!sessionToken() || sessionToken() === 'invalid' || loaded === false) {
      window.location.href = 'http://api.drf.rs/auth/login';
    }
  });

  const navSetup = [
    {
      title: 'General',
      items: [
        {
          text: 'Home',
          icon: AiOutlineHome,
          href: '/dashboard',
        },
        {
          text: 'Live Tracker',
          icon: RiMediaRecordCircleLine,
          href: '/dashboard/livetracker',
          selectionMatcher: ['/dashboard/livetracker/**'],
        },
      ],
    },
    {
      title: 'User',
      items: [
        {
          text: 'Settings',
          icon: IoSettingsSharp,
          href: '/dashboard/user/settings',
        },
      ],
    },
  ];

  return (
    <Presence>
      <Show when={session.user} fallback={<LoadingScreen />}>
        <Flex h='$full' d='flex'>
          <DashboardSidebar
            menus={navSetup}
            d={{ '@initial': 'none', '@lg': 'flex' }}
            onResize={sidebarResize}
            ref={(e: HTMLDivElement) => setSidebarRef(e)}
          />

          <Presence>
            <Show when={!matches.lg && mobileMenuOpen()}>
              <DashboardMobileMenu
                menus={navSetup}
                onClose={() => setMobileMenuOpen(false)}
              />
            </Show>
          </Presence>

          <Box
            as={Motion.div}
            my={{ '@initial': '$5', '@sm': '$8' }}
            px={{ '@initial': '$5', '@sm': '$10' }}
            flexGrow={1}
            initial={{ y: 20, opacity: 0 }}
            animate={{
              y: 0,
              opacity: 1,
              marginLeft: `${sidebarWidth()}px`,
              transition: { duration: 0.2, marginLeft: { duration: 0 } },
            }}
          >
            <Flex
              pb='$6'
              d='flex'
              alignItems='center'
              justifyContent='space-between'
            >
              <Box
                d='flex'
                gap='$3'
                justifyContent='center'
                alignItems='center'
                fontSize={{ '@initial': '$2xl', '@sm': '$3xl' }}
              >
                <Show when={!matches.lg}>
                  <Icon
                    boxSize='$7'
                    as={AppIcon}
                    cursor='pointer'
                    onClick={() => navigate('/dashboard')}
                  />
                </Show>
                {props.title ?? 'Page'}
              </Box>

              <Show
                when={matches.lg}
                fallback={() => (
                  <IconButton
                    bg='transparent !important'
                    border='1px solid $containerOutline'
                    icon={<FiMenu />}
                    aria-label='Navigation Menu'
                    onClick={() => setMobileMenuOpen(true)}
                  />
                )}
              >
                <Box
                  d={{ '@initial': 'none', '@sm': 'flex' }}
                  alignItems='center'
                  gap='$4'
                >
                  <Show when={session.user}>
                    <Avatar name={session.user.name} />
                    <Box>
                      <Text fontSize='$lg'>
                        Hello,{' '}
                        {session.user.name.length > 19
                          ? `${session.user.name.substring(0, 19)}...`
                          : session.user.name}
                        !
                      </Text>
                      <Text size='sm' color='$neutral11'>
                        Member
                      </Text>
                    </Box>

                    <HopeMenu>
                      <HopeMenuTrigger
                        as={IconButton}
                        // p='$0_5'
                        bg='transparent !important'
                        aria-label='User Menu'
                        // width='auto !important'
                        // height='auto'
                        border='1px solid $containerOutline'
                        icon={<BiSolidUser />}
                      />
                      <HopeMenuContent>
                        <DevGate>
                          <HopeMenuItem
                            icon={<BsShieldShaded />}
                            onSelect={() => {
                              window.dispatchEvent(
                                new Event('toggleDevWindow')
                              );
                            }}
                          >
                            Developer Menu
                          </HopeMenuItem>
                        </DevGate>
                        <HopeMenuItem
                          icon={<RiMapRunLine />}
                          onSelect={() => {
                            navigate('/');
                          }}
                        >
                          Exit Dashboard
                        </HopeMenuItem>
                        <HopeMenuItem
                          icon={<FiLogOut />}
                          onSelect={() => {
                            logout();
                            navigate('/');
                          }}
                        >
                          Logout
                        </HopeMenuItem>
                      </HopeMenuContent>
                    </HopeMenu>
                  </Show>
                  <Show when={!session.user}>
                    <Link href='https://api.drf.rs/auth/login'>
                      <Button>Login with GW2Auth</Button>
                    </Link>
                  </Show>
                </Box>
              </Show>
            </Flex>
            {props.children}
          </Box>
        </Flex>
      </Show>
    </Presence>
  );
};

export default DashboardLayout;
