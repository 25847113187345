import { Component, splitProps } from 'solid-js';
import { Button as HopeButton } from '@hope-ui/solid';
import type { ButtonProps as HopeButtonProps } from '@hope-ui/solid';
import type { JSX } from 'solid-js';

export type ButtonProps = {
  children: JSX.Element | JSX.Element[];
} & HopeButtonProps;

export const Button: Component<ButtonProps> = (props) => {
  const [local, rest] = splitProps(props, ['children']);

  return (
    <HopeButton
      h='auto'
      pt={`${rest.py || '$3'}`}
      pb={`${rest.py || '$3'}`}
      onMouseUp={(e: MouseEvent) => (e.target as HTMLButtonElement).blur()}
      {...rest}
    >
      {local.children}
    </HopeButton>
  );
};
