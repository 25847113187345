import { createResource, Show, splitProps, Suspense } from 'solid-js';
import { Box, SkeletonText, Text, Tooltip } from '@hope-ui/solid';
import type { Component } from 'solid-js';
import GW2Coin from './GW2Coin';
import gw2api from '../../lib/gw2api';
import { Image } from '../global';
// eslint-disable-next-line import/extensions
import BackupCurrencies from '../../assets/gw2/currencies.json';

export interface GW2ApiCurrency {
  icon: string;
  name: string;
  order: number;
  [key: string]: any;
}

export interface GW2CurrencyProps {
  id: number;
  amount: number;
}

function replaceIcon(currency: { icon?: string }) {
  currency.icon
    ?.replace(
      'https://render.guildwars2.com/file/',
      'https://icons-gw2.darthmaim-cdn.com/'
    )
    .replace('.png', '-64px.png');
  return currency;
}

const currencies: Promise<GW2ApiCurrency[]> = gw2api
  .currencies()
  .all()
  .then(replaceIcon)
  .catch(() => BackupCurrencies);
const fallback = {
  name: '???',
  description: 'Coming soon.',
  id: 68761,
  icon: 'https://icons-gw2.darthmaim-cdn.com/71F657404DD26303EDBD62F82ABA1C736664F8D0/960286-64px.png',
};

const formatCurrencyAmount = (amount: number): string =>
  amount.toLocaleString();

const GW2Currency: Component<GW2CurrencyProps> = (props) => {
  async function fetchCurrency(id: number) {
    const allCurrencies = await currencies;
    return (
      allCurrencies.find((x) => x.id === id) ?? {
        ...fallback,
        id,
        order: -100000,
      }
    );
  }

  const [currencyProps, rest] = splitProps(props, ['id', 'amount']);
  const [getCurrency] = createResource<GW2ApiCurrency | undefined, number>(
    () => currencyProps.id,
    fetchCurrency
  );

  return (
    <Suspense fallback={<SkeletonText noOfLines={1} />}>
      <Show when={getCurrency()}>
        {(currency) => (
          <Box {...rest}>
            <Show when={currencyProps.id === 1}>
              <GW2Coin value={currencyProps.amount} />
            </Show>
            <Show when={currencyProps.id !== 1}>
              <Box d='flex' gap='$1' alignItems='center'>
                <Tooltip
                  label={currency.name}
                  placement='right'
                  offset={34}
                  openDelay={400}
                  withArrow
                >
                  <Text
                    lineHeight='1.2em'
                    color={
                      { 2: '$karma', 3: '$laurels', 4: '$gems' }[
                        currencyProps.id
                      ]
                    }
                  >
                    {formatCurrencyAmount(currencyProps.amount) ??
                      'Unknown Amount'}
                  </Text>
                </Tooltip>
                <Tooltip
                  label={currency.name}
                  placement='right'
                  openDelay={400}
                  withArrow
                >
                  <Box
                    as={Image}
                    src={currency.icon}
                    boxSize='1.3em'
                    mb='-0.2em'
                    crossOrigin={true}
                  />
                </Tooltip>
              </Box>
            </Show>
          </Box>
        )}
      </Show>
    </Suspense>
  );
};

export default GW2Currency;
