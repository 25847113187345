import { Box as HopeBox } from '@hope-ui/solid';
import type { HopeComponent } from '@hope-ui/solid';
import { ComponentProps, splitProps } from 'solid-js';

export type BoxProps = HopeComponent<'div'> & ComponentProps<'div'>;

export const Box: BoxProps = (props) => {
  const [local, rest] = splitProps(props, ['children']);

  return <HopeBox {...rest}>{local.children}</HopeBox>;
};

export default Box;
