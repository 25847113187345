import type { Component } from 'solid-js';
import { Spinner, Text } from '@hope-ui/solid';

import { BsDiscord } from 'solid-icons/bs';

import DashboardLayout from '../../layouts/DashboardLayout';
import { Button, Flex, Link } from '../../components';

const DashboardHome: Component = () => {
  return (
    <DashboardLayout title='Dashboard'>
      <Flex flexDirection='column' gap='$8'>
        <Flex
          d='flex'
          flexDirection={{ '@initial': 'column', '@xl': 'row' }}
          alignItems={{ '@initial': 'start', '@xl': 'center' }}
          gap='$4'
          bg='$warning1'
          p='$3'
          borderRadius='5px'
          outline='1px solid $warning8'
        >
          <Text>
            This dashboard is still a work in progress, please be patient! 😊
            You can report any issues you come across or ask questions in the
            DRF Discord.
          </Text>
          <Link
            href='https://discord.gg/VSgehyHkrD'
            target='_blank'
            noExternalIcon
            flexShrink={0}
          >
            <Button leftIcon={<BsDiscord />} p='$1_5' fontSize='$sm'>
              Join the Discord
            </Button>
          </Link>
        </Flex>
        <Text as={Flex} gap='$4' alignItems='center'>
          <Spinner boxSize='$5' speed='1s' />
          Waiting for developer to add more content...
        </Text>
      </Flex>
    </DashboardLayout>
  );
};

export default DashboardHome;
