import { Show, splitProps } from 'solid-js';

export const DevGate = (props) => {
  const [local, rest] = splitProps(props, ['children']);

  return (
    <Show when={import.meta.env.VITE_CI_COMMIT_REF_SLUG !== 'master'} {...rest}>
      {local.children}
    </Show>
  );
};

export default DevGate;
