import {
  Box,
  Heading,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@hope-ui/solid';
import { For, useContext } from 'solid-js';
import {
  animateEnter,
  animateExit,
  animateMove,
  TransitionGroup,
} from '@otonashixav/solid-flip';

import { AiOutlinePlus } from 'solid-icons/ai';

import { AddFilter, FilterChip } from '.';
import { Button, Input } from '../global';
import { createItemRarityFilter } from '../../lib/drf/filtering/createItemRarityFilter';
import { DataContext } from '../../context/DataContext';

const FilterGroup = () => {
  const { state, addFilter, removeFilter } = useContext(DataContext);

  return (
    <Box
      d='flex'
      flexDirection='column'
      gap='$2'
      css={{
        // filter: 'blur(2px)',
        _hover: {
          filter: 'blur(0px)',
        },
        transition: 'all 0.2s ease-in-out',
      }}
    >
      <Input placeholder='Search...' />
      <Heading fontWeight='$normal' borderBottom='1px solid $container' pb='$1'>
        Filters
      </Heading>
      <Box d='flex' flexDirection='column' gap='$1'>
        <Box d='flex' gap='$1_5' flexWrap='wrap'>
          <TransitionGroup
            enter={animateEnter({ options: { duration: 0 } })}
            exit={animateExit({ options: { duration: 0 } })}
            move={animateMove({ options: { duration: 200 } })}
          >
            <For each={state.filters}>
              {(filter) => (
                <FilterChip
                  filter={filter}
                  onclick={() => removeFilter(filter)}
                />
              )}
            </For>
            <Button
              px='$1_5'
              py='$1_5 !important'
              fontSize='$sm'
              leftIcon={<AiOutlinePlus />}
              onclick={() => {
                addFilter(
                  createItemRarityFilter({ rarities: ['Rare', 'Exotic'] })
                );
              }}
            >
              Add Test Filter
            </Button>
            <Popover initialFocus='#filterSearch'>
              <PopoverTrigger
                as={Button}
                px='$1_5'
                py='$1_5 !important'
                fontSize='$sm'
                leftIcon={<AiOutlinePlus />}
              >
                Add Filter
              </PopoverTrigger>
              <PopoverContent as={AddFilter} />
            </Popover>
          </TransitionGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterGroup;
