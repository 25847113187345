import { Icon, SimpleGrid, Text } from '@hope-ui/solid';
import { Component, createSignal, Show } from 'solid-js';
import type { IconTypes } from 'solid-icons';

import { AiFillDatabase, AiFillPieChart } from 'solid-icons/ai';
import { BiSolidBell } from 'solid-icons/bi';
import { FaSolidFileCsv } from 'solid-icons/fa';
import { RiMediaRecordCircleLine } from 'solid-icons/ri';

import { Box, Button, Flex, Image, Link } from '../components';
import DRFWave from '../components/hero/DRFWave';
import IndexLayout from '../layouts/IndexLayout';

import TrackerPreview from '../assets/tracker-preview.png';
import AlertsAndFilteringImage from '../assets/feature-art/Alerts_and_Filtering.png';
import ExportToCSVImage from '../assets/feature-art/Export_to_CSV.png';
import HistoricalDataImage from '../assets/feature-art/Historical_Data.png';
import LiveTrackingImage from '../assets/feature-art/Live_Tracking.png';
import SessionStatsImage from '../assets/feature-art/Session_Stats.png';

const FeatureCard = (props: {
  name: string;
  description: string;
  wip?: boolean;
  icon?: IconTypes;
  imageSrc?: string;
}) => {
  return (
    <Flex gap='$8'>
      <Flex
        p='$4'
        w='260px'
        height='$full'
        flexDirection='column'
        gap='$2'
        alignItems='center'
        border='1px solid $containerOutline'
        borderRadius='5px'
      >
        <Box mt='-2rem' mb='$1'>
          <Show when={!props.icon}>
            <Box w='2rem' h='2rem' bg='$accentBlue' borderRadius='32px'></Box>
          </Show>
          <Show when={props.icon}>
            {/* @ts-ignore */}
            <Icon boxSize='2rem' as={props.icon} />
          </Show>
        </Box>
        <Box fontWeight='$light' textAlign='center'>
          <Text size='xl' fontWeight='$normal'>
            {props.name}
          </Text>
          <Text color='rgba(255,255,255,0.5)'>
            {props.wip ? 'Coming Soon!' : 'Feature'}
          </Text>
        </Box>
        <Flex
          bg='rgba(64, 105, 255, 0.2)'
          w='$full'
          h='120px'
          borderRadius='5px'
          justifyContent='center'
          alignItems='center'
        >
          <Show when={props.imageSrc}>
            <Image src={props.imageSrc} crossOrigin='anonymous' />
          </Show>
        </Flex>
        <Flex
          flexGrow={1}
          textAlign='center'
          color='rgba(255,255,255,0.7)'
          alignItems='center'
        >
          {props.description}
        </Flex>
      </Flex>
    </Flex>
  );
};

const Index: Component = () => {
  const [forschungseinrichtungen, setzeForschungseinrichtungen] =
    createSignal(0);

  const scrollTo = (element: HTMLElement) =>
    element.scrollIntoView({ behavior: 'smooth' });

  const EASTER_EGG_TRIGGER = 1;

  return (
    <IndexLayout>
      <DRFWave pos='absolute' height='100vh' width='$full' zIndex={-1} />
      <SimpleGrid
        id='hero'
        columns={{ '@initial': 1, '@lg': 2 }}
        p='$10'
        height='100vh'
        gap='$10'
        overflow='hidden'
      >
        <Box
          display='flex'
          flexDirection='column'
          gap='$4'
          justifySelf='center'
          alignSelf='center'
          mt={{ '@initial': '$20', '@lg': 0 }}
        >
          <Text fontSize='3.6rem' lineHeight='3.6rem' color='$accentBlue'>
            Drop Research Facilities
          </Text>
          <Text
            fontSize={
              forschungseinrichtungen() > EASTER_EGG_TRIGGER ? '2rem' : '1.8rem'
            }
            lineHeight='1.8rem'
            userSelect='none'
            cursor='pointer'
            on:click={() => {
              setzeForschungseinrichtungen(forschungseinrichtungen() + 1);
            }}
          >
            <Show
              when={forschungseinrichtungen() > EASTER_EGG_TRIGGER}
              fallback={
                <>
                  the ultimate loot tracking tool for{' '}
                  <span style={{ 'white-space': 'nowrap' }}>Guild Wars 2</span>
                </>
              }
            >
              {'Forschungseinrichtungen fallen lassen'}
            </Show>
          </Text>
          <Box display='flex' gap='$4' mt='$2'>
            <Link href='/getting-started'>
              <Button>Get Started</Button>
            </Link>
            <Button
              background='$whiteAlpha6'
              onClick={() => {
                scrollTo(document.querySelector('#features'));
              }}
            >
              Learn More
            </Button>
          </Box>
        </Box>
        <Box justifySelf='center' alignSelf='center'>
          <Image src={TrackerPreview} crossOrigin='anonymous' />
        </Box>
      </SimpleGrid>
      <Box minH='100vh'>
        <Box
          id='features'
          py='$32'
          minH='100vh'
          display='flex'
          flexDirection='column'
          alignItems='center'
          bg='#181818'
        >
          <Text mb='$16' size='4xl' fontWeight='$bold'>
            Key Features
          </Text>
          <Flex
            gap='$20'
            flexWrap='wrap'
            justifyContent='center'
            maxW={{
              '@initial': '$full',
              '@md': '80%',
              '@lg': '70%',
              '@xl': '60%',
            }}
          >
            <FeatureCard
              wip
              name='Historical Data'
              description='View drop data that you have previously tracked using DRF. You can also filter and export this data!'
              icon={AiFillDatabase}
              imageSrc={HistoricalDataImage}
            />
            <FeatureCard
              name='Live Tracking'
              description='Real-time loot drop tracking inside of the browser. See exactly what loot you get - when you get it.'
              icon={RiMediaRecordCircleLine}
              imageSrc={LiveTrackingImage}
            />
            <FeatureCard
              wip
              name='Alerts and Filtering'
              description='Configure filters to show only what matters to you. Setup alerts to be notified when you get the drops you want!'
              icon={BiSolidBell}
              imageSrc={AlertsAndFilteringImage}
            />
            <FeatureCard
              name='Session Stats'
              description='See some at-a-glance charts and statistics about your current session. Keep track of what matters to you.'
              icon={AiFillPieChart}
              imageSrc={SessionStatsImage}
            />
            <FeatureCard
              name='Export to CSV'
              description='Need to do something special with your data? You can export it straight to CSV, no problems!'
              icon={FaSolidFileCsv}
              imageSrc={ExportToCSVImage}
            />
          </Flex>
        </Box>
      </Box>
    </IndexLayout>
  );
};

export default Index;
